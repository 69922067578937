import { call, put, takeLatest } from 'redux-saga/effects';
import DownloadFile from './DownloadFile';
import { downloads } from './api';

export function* fetchDownloads(action) {
  try {
    const downloadsData = yield call(downloads.fetchDownloads, action.payload);
    yield put({type: 'FETCH_DOWNLOADS_SUCCESS', payload: downloadsData});
  } catch (e) {
    yield put({type: 'FETCH_DOWNLOADS_FAILED'});
  }
}

export function* downloadFile(action) {
  try {
    const downloadedFile = yield call(downloads.downloadFile, action.payload);
    yield put({type: 'DOWNLOAD_FILE_SUCCESS', payload: downloadedFile});
    yield put({type: 'SHOW_MODAL', payload: {
      type: 'error',
      title: 'Votre fichier CSV a été généré.',
      component: DownloadFile,
      componentProps: downloadedFile,
      onValidationTitle: 'Merci',
      onValidation: () => console.log('Closed'),
    }});
  } catch (e) {
    yield put({type: 'DOWNLOAD_FILE_FAILED'});
  }
}

function* downloadSaga() {
  yield takeLatest('FETCH_DOWNLOADS', fetchDownloads);
  yield takeLatest('DOWNLOAD_FILE', downloadFile);
}

export default downloadSaga;
