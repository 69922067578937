export const GO_TO_PATH = 'GO_TO_PATH';
export const goToPath = (path) => ({
  type: GO_TO_PATH, payload: path
});

export const GET_ACCESS = 'GET_ACCESS';
export const GET_ACCESS_SUCCESS = 'GET_ACCESS_SUCCESS';
export const GET_ACCESS_FAILED = 'GET_ACCESS_FAILED';
export const getAccess = (params) => ({
  type: GET_ACCESS, payload: params
});

export const SELECT_FOLDER = 'SELECT_FOLDER';
export const selectFolder = (folder) => ({
  type: SELECT_FOLDER, payload: folder
});

export const SELECT_ALL_FOLDER = 'SELECT_ALL_FOLDER';
export const selectAllFolder = () => ({
  type: SELECT_ALL_FOLDER
});

export const UNSELECT_ALL = 'UNSELECT_ALL';
export const unselectAll = () => ({
  type: UNSELECT_ALL
});

export const TOGGLE_SELECTION_MODE = 'TOGGLE_SELECTION_MODE';
export const toggleSelectionMode = () => ({
  type: TOGGLE_SELECTION_MODE
});

export const SEND_SELECTED_FOLDERS = 'SEND_SELECTED_FOLDERS';
export const SEND_SELECTED_FOLDERS_SUCCESS = 'SEND_SELECTED_FOLDERS_SUCCESS';
export const SEND_SELECTED_FOLDERS_FAILED = 'SEND_SELECTED_FOLDERS_FAILED';
export const sendSelectedFolders = (params) => ({
  type: SEND_SELECTED_FOLDERS, payload: params
});

export const BLANK_EXPLORER = 'BLANK_EXPLORER';
