import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
} from './actions';

const initialState = {
  open: true,
};

export default function (state= initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR: {
        return {
          ...action.payload,
          open: true,
        };
      }
      break;
    case HIDE_SNACKBAR:
      return { open: false };
      break;
    default:
      return state;
      break;
  }
}
