import { combineReducers } from 'redux';
/* Reducers import */
import authReducer from '../components/Auth/reducer';
import snackbarReducer from '../lib/Snackbar/reducer';
import dropdownReducer from '../lib/Dropdown/reducer';
import modalReducer from '../lib/Modal/reducer';
import explorerReducer from '../components/Explorer/reducer';
import downloadReducer from '../components/Download/reducer';

const createReducer = asyncReducers =>
  combineReducers({
    auth: authReducer,
    snackbar: snackbarReducer,
    dropdowns: dropdownReducer,
    modal: modalReducer,
    explorer: explorerReducer,
    download: downloadReducer,
  });

export default createReducer;
