import React from 'react';

class DownloadFile extends React.Component {
  render() {
    console.log(this.props);
    return (
      <div>
        Votre fichier CSV est prêt!
        <div className={"d-flex justify-content-center"}>
          <a
            className={"btn btn-success"}
            download
            href={this.props.download_url}
          >Télécharger</a>
        </div>
      </div>
    );
  }
}

export default DownloadFile;
