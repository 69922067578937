import React from 'react';
import './style.css';

const style = {
  default: {
      outline: 'none',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
  },
  popover: {
  }
};

class Button extends React.Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (props.toggled != state.toggled) {
      return {
        toggled: props.toggled,
      };
    }
    return null;
  }

  handleClick(e) {
    const { onClick, mode } = this.props;
    if (mode === 'toggle') {
      if (this.btn) {
        if (this.btn.classList.contains('toggled')) {
          this.btn.classList.remove('toggled');
        } else {
          this.btn.classList.add('toggled');
        }
      }
    }

    if (onClick) {
      onClick(e);
    }

  }

  render() {
    const { children, popoverMessage, toggled, disabled, type, href, target } = this.props;

    switch (type) {
      case 'link':
      return (
        <a
          style={style.default}
          ref={(btn => this.btn = btn)}
          className={"btn-somesca popover-parent " + (toggled?'toggled':'')}
          href={href}
          target={target}
          disabled={disabled}
        >
          {children}

          {
            popoverMessage &&
              <div ref={popover => this.popover = popover} className={"popover-somesca"} style={style.popover}>
                {popoverMessage}
              </div>
          }
        </a>
      );
        break;
      default:
        return (
          <button
            style={style.default}
            ref={(btn => this.btn = btn)}
            className={"btn-somesca popover-parent " + (toggled?'toggled':'')}
            onClick={this.handleClick.bind(this)}
            disabled={disabled}
          >
            {children}

            {
              popoverMessage &&
                <div ref={popover => this.popover = popover} className={"popover-somesca"} style={style.popover}>
                  {popoverMessage}
                </div>
            }
          </button>
        );
        break;
    }


  }
}

export default Button;
