import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import AuthRoute from './components/Auth/AuthRoute';
import AdminRoute from './components/Auth/AdminRoute';
import Snackbar from './lib/Snackbar/Snackbar';
import Modal from './lib/Modal/Modal';
import {
  Main,
  AccountValidation,
  ResetPassword,
  ResetPasswordEmailForm,
  ForgotIdentifier,
  ValidationRevival,
  Download,
} from './pages';
import history from './history';
import './App.css';

function App() {
  return (
    <Router history={history}>
      <>
        <Switch>

          {/* Main  */}
          <AuthRoute exact path={ "/" } component={Main}/>

          {/* Account validation route */}
          <Route exact path={"/account/validation"} component={AccountValidation}/>
          {/* Reset password route */}
          <Route exact path={"/reset-password"} component={ResetPassword}/>
          {/* Reset password Email form */}
          <Route exact path={"/reset-password/demand"} component={ResetPasswordEmailForm}/>
          {/* Oublie d'identifiant */}
          <Route exact path={"/forgot-identifier"} component={ForgotIdentifier}/>
          {/* Relance de validation de compte */}
          <Route exact path={"/account/validation/revival"} component={ValidationRevival}/>

          {/* Admin route for table download
            <AdminRoute exact path={"/download"} component={Download}/>
          */}


          {/* Default Route */}
          <Route render={() =>(<Redirect to={ "/" } />)} />

        </Switch>
        <Snackbar />
        <Modal />
        <div id={"main-overlay"}></div>
      </>
    </Router>
  );
}

export default App;
