import React from 'react';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import RippleEffect from '../../lib/Ripple/RippleEffect';
import { fetchDownloads, downloadFile } from './actions';
import { connect } from 'react-redux';

const style = {
  button: {
    background: 'rgba(0, 0, 0, .3)',
    border: 'none',
    color: 'white',
    padding: '.5rem',
    borderRadius: '5px'
  }
}

class DownloadForm extends React.Component {
  state = {
    tstats: {},
    tclient: {},
    modified: true,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.modified === true && (props.downloads.tstats && props.downloads.tclient)) {
      return {
        tstats: props.downloads.tstats,
        tclient: props.downloads.tclient,
        modified: false,
      }
    }
    return null;
  }

  componentDidMount() {
    const params = {
      access_token: this.props.auth.user.token,
    };
    this.props.fetchDownloads(params);
  }

  changeStatsDate(e) {
    let newState = {
      ...this.state,
      tstats: {
        ...this.state.tstats,
        date: e.target.value,
      },
    };

    this.setState(newState);
  }

  download(filename, date) {
    const params = {
      access_token: this.props.auth.user.token,
      filename,
      from: date,
    };

    this.props.downloadFile(params);
    //
    this.setState({
      ...this.state,
      modified: true,
    });
  }

  render() {

    const { fetching } = this.props;

    if (fetching === true) return <LoadingComponent />;

    if (this.state.tstats && this.state.tclient) {

      return (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="label--form">
                  Télécharger la donnée <em>Client</em>
                </div>
                <RippleEffect onClick={() => this.download('tclient', null)}>
                 <button type={"button"} style={style.button} className={"btn-block"}>
                  Générer le fichier CSV
                 </button>
                </RippleEffect>
                <hr/>
                <div className="label--form">
                  Télécharger la donnée <em>Statistique</em> à partir du
                  <input
                    type="date"
                    name="tstats_date"
                    className={"form-control mb-2"}
                    value={this.state.tstats.date}
                    onChange={(e) => this.changeStatsDate(e)}
                  />
                </div>
                <RippleEffect onClick={() => this.download('tstats', this.state.tstats.date)}>
                  <button type={"button"} style={style.button} className={"btn-block"}>
                    Générer le fichier CSV
                  </button>
                </RippleEffect>
              </div>
            </div>
          </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  downloads: state.download.downloads,
  fetching: state.download.fetching,
});

export default connect(mapStateToProps, { fetchDownloads, downloadFile })(DownloadForm);
