import React from 'react';
import Button from '../Button/Button';
import Dropdown from '../../lib/Dropdown/Dropdown';
import DropdownItem from '../../lib/Dropdown/DropdownItem';
import UserForm from '../../components/User/UserForm';
import { openMailModal } from '../../components/User/actions';
import { logout } from '../Auth/actions';
import { connect } from 'react-redux';
import { confs } from '../../conf';
import logoPNG from '../../public/img/letter-s.png';
import { Link } from 'react-router-dom';
import RippleEffect from '../../lib/Ripple/RippleEffect';

const { PRIMARY_BG, URL_API } = confs;

const style = {
  header: {
    padding: '.5rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    background: PRIMARY_BG,
    color: 'white',
  },
  userInfos: {
    flex: '1',
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
  },
  tools: {
    display: 'flex',
    alignItems: 'center',
  },
  ddItem: {
    background: 'white',
    color: 'rgba(0,0,0,.5)',
    padding: '.5rem 1rem',
  },
  button: {
    background: 'rgba(0, 0, 0, .3)',
    border: 'none',
    color: 'white',
    padding: '.5rem 1rem',
    borderRadius: '5px',
    fontSize: '1rem',
    textDecoration: 'none',
  },
};

const UserButton = () => (
  <div style={{ marginRight: '.5rem'}}>
    <i className="fa fa-user-circle mx-1 my-1"></i>
  </div>
)

const Menu = () => (
  <div  style={{
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.5rem',
  }} className={"btn-somesca"}>
    <i className="fa fa-ellipsis-v"></i>
  </div>
)

class InformationHeader extends React.Component {
  logout () {
    const params = {
      access_token: this.props.auth.user.token,
    };

    this.props.logout(params);
  }

  render() {
    return (
      <div style={style.header}>
        <div style={style.userInfos} className={"ellipsis"}>
          <div className={"ellipsis d-flex align-items-center"}>
            <i className="fa fa-user-circle mr-2"></i>
            <span className={"ellipsis mr-2"}>
              {this.props.auth.user.societe} / {this.props.auth.user.prenom} {this.props.auth.user.nom}
            </span>
          </div>
        </div>
        <div style={style.tools}>
          <Button popoverMessage={"somesca.fr"} type={"link"} href="http://somesca.fr/debut.html" target={"_blank"}>
            <img src={logoPNG} style={{width: '15px'}} className={"img-fluid"} alt=""/>
          </Button>
          <Dropdown Header={Menu} name={"main-dropdown"}>
            <DropdownItem
              icon={"envelope"}
              label={"Envoyer un mail à Somesca"}
              onClick={() => this.props.openMailModal()}
            />
            <DropdownItem
              icon={"user"}
              label={"Modifier mon mot de passe"}
              to={"/reset-password"}
              replace={true}
              closeOnClick={"main-dropdown"}
            />
            {/*
              this.props.auth.user.administrateur === true &&
                <DropdownItem icon={"download"} label={"Téléchargements"} to={"/download"} replace={true}/>
            */}
            <DropdownItem
              icon={"file-pdf-o"}
              download
              target={"_blank"}
              href={URL_API + "/somesca/images/cgu.pdf"}
              label={"Conditions d'utilisation"}
              closeOnClick={"main-dropdown"}
            />
            <DropdownItem
              icon={"power-off"}
              label={(this.props.logoutLoading?'Déconnexion ...':"Se déconnecter")}
              onClick={() => this.logout()}
            />
          </Dropdown>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  logoutLoading: state.auth.logoutLoading,
});

export default connect(mapStateToProps, { logout, openMailModal  })(InformationHeader);
