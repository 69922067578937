import axios from 'axios';
import qs from 'qs';
import { confs } from '../../conf';

const { URL_API } = confs;

export const auth = {
  register: (user) =>
    axios.post(URL_API+'/users/register', qs.stringify(user))
      .then(res => res.data),
  login: (credentials) =>
    axios.post(URL_API+'/users/login', qs.stringify(credentials))
      .then(res => res.data),
  logout: (access_token) =>
    axios.post(URL_API+'/users/logout', qs.stringify(access_token))
      .then(res => res.data),
  confirm: (access_token) =>
   axios.get(URL_API+'/users/confirm', { params: {access_token} })
    .then(res => res.data),
  postUser: (params) =>
   axios.post(URL_API+'/users', qs.stringify(params))
    .then(res => res.data.data),
  putUser: (params) =>
   axios.put(URL_API+'/users', qs.stringify(params))
    .then(res => res.data.data),
  validationAccount: (params) =>
   axios.post(URL_API+'/account/validation', qs.stringify(params))
   .then(res => res.data.data),
  resetPassword: (params) =>
   axios.post(URL_API+'/reset-password', qs.stringify(params))
   .then(res => res.data.data),
  resetPasswordDemand: (params) =>
   axios.post(URL_API+'/reset-password/demand', qs.stringify(params))
   .then(res => res.data.data),
  forgotIdentifier: (params) =>
   axios.post(URL_API+'/forgot-identifier', qs.stringify(params))
   .then(res => res.data.data),
  sendRevival: (params) =>
   axios.post(URL_API+'/account/validation/revival', qs.stringify(params))
   .then(res => res.data.data),
};
