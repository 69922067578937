import { call, put, take, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import history from '../../history';
import { auth } from './api';

// register
export function* register (action) {
  try {
    let userRegistered  = yield call(auth.register, action.payload);
    yield put({type: 'REGISTER_SUCCESS', payload: userRegistered});
  } catch (e) {
    yield put({type: 'REGISTER_FAILED', payload: e})
  }
}
// login
export function* login (action) {
  try {
    let authed  = yield call(auth.login, action.payload);
    yield put({type: 'LOGIN_SUCCESS', payload: authed});
  } catch (e) {
    yield put({type: 'LOGIN_FAILED', payload: e})
    yield put({type: 'SHOW_MODAL', payload: {
      type: 'error',
      title: e.response.data?e.response.data:'Erreur inconnue.',
      onValidationTitle: 'Fermer',
      onValidation: () => console.log('Close'),
    }});
  }
}
// logout
export function* logout (action) {
  try {
    let logedOut  = yield call(auth.logout, action.payload);
    yield put({type: 'LOGOUT_SUCCESS', payload: logedOut});
    yield put({type: 'BLANK_EXPLORER'});
  } catch (e) {
    yield put({type: 'LOGOUT_FAILED', payload: e})
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: "Echec de la déconnexion: votre session s'est terminée.",
        onStart: () => history.replace('/'),
        delay: 5000,
        type: 'error',
        float: true,
      }
    });
  }
}
// confirm
export function* confirm (action) {
  try {
    let confirmedUser  = yield call(auth.confirm, action.payload);
    yield put({type: 'CONFIRM_SUCCESS', payload: confirmedUser});
  } catch (e) {
    yield put({type: 'CONFIRM_FAILED', payload: e})
  }
}
// postUser
export function* postUser (action) {
  try {
    let user  = yield call(auth.postUser, action.payload);
    yield put({type: 'POST_USER_SUCCESS', payload: user});
    const params = {
      access_token: user.token,
    };
    yield put({ type: 'LOGOUT', payload: params});
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: user.message_confirmation,
        onStart: () => history.replace('/'),
        delay: 5000,
        type: 'success',
        float: true,
      }
    });
  } catch (e) {
    yield put({type: 'POST_USER_FAILED', payload: e})
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: e.response.data,
        type: 'error',
        float: true,
      }
    });
  }
}
// postUser
export function* putUser (action) {
  try {
    let user  = yield call(auth.putUser, action.payload);
    yield put({type: 'PUT_USER_SUCCESS', payload: user});
    const params = {
      access_token: user.token,
    };
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Tous les modifications ont été prises en compte.',
        type: 'success',
        float: true,
      }
    });
  } catch (e) {
    yield put({type: 'PUT_USER_FAILED', payload: e})
  }
}
// account validation
export function* validationAccount (action) {
  try {
    let user  = yield call(auth.validationAccount, action.payload);
    yield put({type: 'VALIDATION_ACCOUNT_SUCCESS', payload: user});
    yield put({ type: 'HIDE_SNACKBAR'});
    history.replace('/');
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Votre compte à été activé.',
        onStart: () => window.location.href = '/',
        onEnd: () => history.push('/'),
        type: 'success',
        float: true,
      }
    });
  } catch (e) {
    yield put({type: 'VALIDATION_ACCOUNT_FAILED', payload: e})
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
      label: e.response.data,
      onStart: () => history.replace('/'),
      type: 'error',
      float: true,
    }
  });
  }
}
// account validation
export function* resetPassword (action) {
  try {
    let user  = yield call(auth.resetPassword, action.payload);
    yield put({type: 'RESET_PASSWORD_SUCCESS', payload: user});
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Votre mot de passe a correctement été réinitialiser.',
        onStart: () => history.replace('/'),
        type: 'success',
        float: true,
      }
    });
  } catch (e) {
    if (e.response.data.status && e.response.data.status.code === 401) {
      yield put({type: 'SHOW_MODAL', payload: {
        type: 'Error',
        title: 'Action interrompue: votre session s\'est terminée.',
        onValidationTitle: 'Se reconnecter',
        onValidation: () => window.location.reload(),
      }});
    } else {
      yield put({type: 'RESET_PASSWORD_FAILED', payload: e})
      yield put({ type: 'HIDE_SNACKBAR'});
      yield put({ type: 'SHOW_SNACKBAR', payload: {
          label: e.response.data,
          type: 'error',
          float: true,
        }
      });
    }
  }
}
// account validation
export function* resetPasswordDemand (action) {
  try {
    let user  = yield call(auth.resetPasswordDemand, action.payload);
    yield put({type: 'RESET_PASSWORD_DEMAND_SUCCESS', payload: user});
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Un mail de réinitilisation de mot de passe vous à été envoyé. Suivez la procédure décrite pour réinitialiser votre mot de passe.',
        onStart: () => history.replace('/'),
        type: 'success',
        float: true,
      }
    });
  } catch (e) {
    if (e.response.data.status && e.response.data.status.code === 401) {
      yield put({type: 'SHOW_MODAL', payload: {
        type: 'Error',
        title: 'Action interrompue: votre session s\'est terminée.',
        onValidationTitle: 'Se reconnecter',
        onValidation: () => window.location.reload(),
      }});
    } else {
      yield put({type: 'RESET_PASSWORD_DEMAND_FAILED', payload: e})
      yield put({type: 'SHOW_MODAL', payload: {
          type: 'error',
          title: e.response.data,
          onValidationTitle: 'Fermer',
          onValidation: () => history.replace('/'),
      }});
    }
  }
}
// account validation
export function* forgotIdentifier (action) {
  try {
    let user  = yield call(auth.forgotIdentifier, action.payload);
    yield put({type: 'FORGOT_IDENTIFIER_SUCCESS', payload: user});
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Un email vous a été envoyé pour la récupération de votre identifiant.',
        onStart: () => history.replace('/'),
        type: 'success',
        float: true,
      }
    });
  } catch (e) {
    yield put({type: 'FORGOT_IDENTIFIER_FAILED', payload: e})
    yield put({type: 'SHOW_MODAL', payload: {
      type: 'error',
      title: e.response.data,
      onValidationTitle: 'Fermer',
      onValidation: () => history.replace('/'),
    }});
  }
}
// account validation
export function* sendRevival (action) {
  try {
    let user  = yield call(auth.sendRevival, action.payload);
    yield put({type: 'SEND_REVIVAL_SUCCESS', payload: user});
    yield put({ type: 'HIDE_SNACKBAR'});
    yield put({ type: 'SHOW_SNACKBAR', payload: {
        label: 'Un email vous a été envoyé pour la validation de votre compte. Si vous ne recevez pas ce mail, contacter Somesca.',
        onStart: () => history.replace('/'),
        type: 'success',
        float: true,
      }
    });
  } catch (e) {
    yield put({type: 'SEND_REVIVAL_FAILED', payload: e})
    yield put({type: 'SHOW_MODAL', payload: {
      type: 'error',
      title: e.response.data,
      onValidationTitle: 'Fermer',
      onValidation: () => history.replace('/'),
    }});
  }
}

function* authSaga() {
  yield takeLatest('REGISTER', register);
  yield takeLatest('LOGIN', login);
  yield takeLatest('LOGOUT', logout);
  yield takeLatest('CONFIRM', confirm);
  yield takeLatest('POST_USER', postUser);
  yield takeLatest('PUT_USER', putUser);
  yield takeLatest('VALIDATION_ACCOUNT', validationAccount);
  yield takeLatest('RESET_PASSWORD', resetPassword);
  yield takeLatest('RESET_PASSWORD_DEMAND', resetPasswordDemand);
  yield takeLatest('FORGOT_IDENTIFIER', forgotIdentifier);
  yield takeLatest('SEND_REVIVAL', sendRevival);
}

export default authSaga;
