import React from 'react';
import RippleEffect from '../lib/Ripple/RippleEffect';
import history from '../history';
import qs from 'qs';
import { resetPassword } from '../components/Auth/actions';
import { confs } from '../conf';
import { connect } from 'react-redux';

const { PRIMARY_BG } = confs;

const style = {
  header: {
    background: PRIMARY_BG,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    width: '100%',
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.2)',
    color: 'white',
    fontSize: '1.5rem',
    padding: '1rem'
  },
  button: {
    background: 'rgba(0, 0, 0, .3)',
    border: 'none',
    color: 'white',
    padding: '.5rem',
    borderRadius: '5px'
  },
}

class ResetPassword extends React.Component {
  state = {
    password: ''
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    },() => console.log(this.state));
  }

  passwordMatches() {
    return (
      this.state.password &&
      this.state.password === this.state.confirm &&
      this.state.password.length >= 7
    );
  }

  resetPassword() {
    const params = {
      access_token: (qs.parse(this.props.location.search.replace('?', '')).access_token) || this.props.auth.user.token,
      ...this.state,
    };

    this.props.resetPassword(params);
  }

  render() {
    return (
      <div>
        <header style={style.header}>
          <div style={{fontSize: '1rem'}}>
            <div className="icon-button" onClick={() => history.replace('/')}>
              <i className="fa fa-arrow-left"></i>
            </div>
          </div>
          <div>
            Réinitialisation de mot de passe
          </div>
          <div></div>
        </header>
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">

                <div className="alert alert-primary">
                  Le mot de passe doit faire au minimum 7 caractères.
                </div>

                <div className="label--form">
                  Nouveau mot de passe
                  <input
                    type="password"
                    name={"password"}
                    placeholder={"Nouveau mot de passe"}
                    className="form-control mb-2"
                    maxLength={25}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div className="label--form">
                  Confirmer
                  <input
                    type="password"
                    name={"confirm"}
                    placeholder={"Confirmer"}
                    className="form-control mb-2"
                    maxLength={25}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>

                {
                  (!this.passwordMatches() && this.state.password.length > 0) &&
                    <div className="alert alert-danger">
                      Les mots de passe ne correspondent pas ou la longueur est inférieure à 7 caractères.
                    </div>
                }

                <RippleEffect onClick={() => this.resetPassword()}>
                  <button
                    type={"button"}
                    disabled={this.props.auth.resetingPassword  || !this.passwordMatches()}
                    style={style.button}
                    className={"btn-block "+ (this.passwordMatches()?'bg-success':'bg-danger')}
                  >
                    {
                      this.props.auth.resetingPassword ?
                        "Réinitialisation en cours ..."
                      :
                        "Réinitialiser mon mot de passe"
                    }
                  </button>
                </RippleEffect>

              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
