import {
  FETCH_DOWNLOADS,
  FETCH_DOWNLOADS_SUCCESS,
  FETCH_DOWNLOADS_FAILED,

  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILED,
} from './actions';

const initialState = {
  downloads: {},
  fetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DOWNLOADS:
      return {
        ...state,
        fetching: true,
      };
      break;
    case FETCH_DOWNLOADS_SUCCESS:
      return {
        ...state,
        downloads: action.payload,
        fetching: false,
      };
      break;
    case FETCH_DOWNLOADS_FAILED:
      return {
        ...state,
        fetching: false,
      };
      break;
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloads: {
          ...state.downloads,
          ...action.payload
        }
      };
      break;
    default:
      return state;
      break;
  }
}
