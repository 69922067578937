import React from 'react';
import { connect } from 'react-redux';
import AuthRoute from './AuthRoute';
import { Route, Redirect } from 'react-router-dom';

class AdminRoute extends React.Component {
  render() {
    const { auth } = this.props;

    if (auth.user && auth.user.administrateur === true)
    return (
      <AuthRoute {...this.props} />
    );

    if ((!auth.user) || !(auth.user.administrateur === true)) {
      return (
        <Redirect to={"/"} />
      )
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { })(AdminRoute);
