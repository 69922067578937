export const FETCH_DOWNLOADS = 'FETCH_DOWNLOADS';
export const FETCH_DOWNLOADS_SUCCESS = 'FETCH_DOWNLOADS_SUCCESS';
export const FETCH_DOWNLOADS_FAILED = 'FETCH_DOWNLOADS_FAILED';
export const fetchDownloads = (params) => ({
  type: FETCH_DOWNLOADS, payload: params
});

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILED = 'DOWNLOAD_FILE_FAILED';
export const downloadFile = (params) => ({
  type: DOWNLOAD_FILE, payload: params
});
