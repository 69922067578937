import React from 'react';
import RippleEffect from '../lib/Ripple/RippleEffect';
import history from '../history';
import { resetPasswordDemand } from '../components/Auth/actions';
import { confs } from '../conf';
import { connect } from 'react-redux';
import qs from 'qs';

const { PRIMARY_BG } = confs;

const style = {
  header: {
    background: PRIMARY_BG,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    width: '100%',
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.2)',
    color: 'white',
    fontSize: '1.5rem',
    padding: '1rem',
  },
  button: {
    background: 'rgba(0, 0, 0, .3)',
    border: 'none',
    color: 'white',
    padding: '.5rem',
    borderRadius: '5px'
  },
}

class ResetPasswordEmailForm extends React.Component {
  state = {};

  componentDidMount() {
    const queryParams = this.props.location.search.replace('?', '');
    this.setState(qs.parse(queryParams));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    },() => console.log(this.state));
  }

  isFormValid() {
    return (
      this.state.identifiant
    )
  }

  resetPasswordDemand() {
    const params = {
      access_token: this.props.auth.user.token,
      ...this.state,
    };

    this.props.resetPasswordDemand(params);
  }

  render() {
    const { identifiant, mail } = this.state;
    return (
      <div>
        <header style={style.header}>
          <div style={{fontSize: '1rem'}}>
            <div className="icon-button" onClick={() => history.replace('/')}>
              <i className="fa fa-arrow-left"></i>
            </div>
          </div>
          <div>
            Mot de passe perdu
          </div>
          <div></div>
        </header>
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">


                <div className="alert alert-warning">
                  Saisissez votre identifiant, <br /> vous recevrez sur l'email associé à votre compte le lien de réinitialisation de votre mot de passe.
                </div>

                <div className="label--form">
                  Identifiant*
                  <input
                    type="text"
                    name={"identifiant"}
                    placeholder={"Identifiant"}
                    className="form-control mb-2"
                    value={identifiant}
                    onChange={(e) => this.handleChange(e)}
                    maxLength={25}
                  />
                </div>

                <RippleEffect onClick={() => this.resetPasswordDemand()}>
                  <button
                    type={"button"}
                    disabled={this.props.auth.resetingPassword  || !this.isFormValid()}
                    style={style.button}
                    className={"btn-block "+ (this.isFormValid()?'bg-success':'bg-danger')}
                  >
                    Faire la demande
                  </button>
                </RippleEffect>

              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { resetPasswordDemand })(ResetPasswordEmailForm);
