import React from 'react';
import './style.css';

class Dropdown extends React.Component {
  state = { open: false };

  openDropdown(e) {
    if (!e.currentTarget.classList.contains('show')) {
      e.currentTarget.classList.add('show');
      document.body.style.overflow = 'hidden';
      var overlay = document.getElementById('main-overlay');
      overlay.classList.add('show');
      overlay.addEventListener('click', () => this.closeDropdown());
    }
  }

  closeDropdown() {
    if (this.dropdown) {
      this.dropdown.classList.remove('show');
      document.body.style.overflow = 'auto';
    }
    var overlay = document.getElementById('main-overlay');
    overlay.classList.remove('show');
  }

  render() {
    const { children, title, header, align } = this.props;
    let Component;
    if (header) {
      Component = header;
    }
    let style;
    if (align === 'right') {
      style = {right: '0'};
    } else {
      style = {left: '0'};
    }

    return (
      <div
        ref={dropdown => this.dropdown = dropdown}
        className="dropdown-box-ils"
        onClick={(e) => this.openDropdown(e)}
      >
        <div className="dropdown-toggler-ils">
          {
            header &&
              <Component />
          }
          {
            title &&
              <span>{title}</span>
          }
        </div>
        <div style={style} className="dropdown-ils">
          {children}
        </div>
      </div>
    );
  }
}

export default Dropdown;
