import {
  GO_TO_PATH,
  GET_ACCESS,
  GET_ACCESS_SUCCESS,
  GET_ACCESS_FAILED,
  //
  SELECT_FOLDER,
  SELECT_ALL_FOLDER,
  UNSELECT_ALL,
  TOGGLE_SELECTION_MODE,

  SEND_SELECTED_FOLDERS,
  SEND_SELECTED_FOLDERS_SUCCESS,
  SEND_SELECTED_FOLDERS_FAILED,
  //
  BLANK_EXPLORER,
} from './actions';
import history from '../../history';
import { findFolderRecursively } from '../../utils';

const initialState = {
  explorer: {},
  currentFolder: {},
  loading: false,
  selectedFolders: [],
  selectionMode: false,
  zipping: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GO_TO_PATH: {
      let path = action.payload;
      let currentFolder = findFolderRecursively(path, state.explorer);
      return {...state, currentFolder};
      break;
    }
    case GET_ACCESS:
      return {...state, loading: true};
    case GET_ACCESS_SUCCESS: {
      if (!state.currentFolder.name) {
        return {...state, loading: false, currentFolder: action.payload,  explorer: action.payload};
      } else {
        return {...state, loading: false, explorer: action.payload};
      }
    }
      break;
    case GET_ACCESS_FAILED:
      return {...state, loading: false, };
      break;
    case SELECT_FOLDER: {
      let index = state.selectedFolders.indexOf(action.payload);
      if (index >= 0) {
        state.selectedFolders.splice(index, 1);
      }else {
        state.selectedFolders.push(action.payload);
      }
      const folders = state.selectedFolders;
      return {
        ...state,
        selectedFolders: [...state.selectedFolders],
      };
      break;
    }
    case SELECT_ALL_FOLDER: {
      for (var i = 0; i < state.currentFolder.content.length; i++) {
        let index = state.selectedFolders.indexOf(state.currentFolder.content[i].path);
        if (index == -1) {
          state.selectedFolders.push(state.currentFolder.content[i].path);
        }
      }
      return {
        ...state,
        selectedFolders: [...state.selectedFolders],
      };
      break;
    }
    case UNSELECT_ALL:
      return {...state, selectedFolders: []};
      break;
    case TOGGLE_SELECTION_MODE: {
      let selectionMode = !state.selectionMode;
      return {
          ...state,
          selectionMode: selectionMode,
          selectedFolders: []
      };
      break;
    }
    case SEND_SELECTED_FOLDERS:
      return {...state, zipping: true};
      break;
    case SEND_SELECTED_FOLDERS_SUCCESS:
      return {...state, zipping: false};
      break;
    case SEND_SELECTED_FOLDERS_FAILED:
      return {...state, zipping: false};
      break;
    case BLANK_EXPLORER:
      return initialState;
      break;
    default:
      return state;
  }
}
