import axios from 'axios';
import qs from 'qs';
import { confs } from '../../conf';

const { URL_API } = confs;

export const user = {
  sendMail: (params) => axios.get(URL_API + '/mails/send', {params}, { withCredentials: true })
    .then(res => res.data),
}
