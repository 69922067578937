import React from 'react';
import { connect } from 'react-redux';
import { hasFile, hasFolder, isIE } from '../../utils';
import { goToPath, unselectAll } from './actions';
import Folder from './Folder';
import File from './File';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ExplorerMenu from './ExplorerMenu';
import './style.css';

const style = {
  title: {
    color: 'rgba(0,0,0,.2)',
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
  },
  container: {
    height: '400px',
    overflowY: 'scroll',
  },
};

class Explorer extends React.Component {
  state = {treeViewOpened: true};

  handleResize()
  {
    alert('ok');
  }

  toggleTreeView() {
    this.setState({
      ...this.state,
      treeViewOpened: !this.state.treeViewOpened,
    });
  }

  backButton() {
    //
    if (this.props.selectionMode) return;
    //
    this.props.goToPath(this.props.currentFolder.previous);
  }

  groupByLetter(currentFolder) {
    let content = currentFolder.content;
    if (currentFolder.type != 'folder') return content;
    if (!content) return;
    let results = content.reduce((memo, folder) => {
      let letter = folder.name.charAt(0);
      if (memo[letter]) {
        let folders = memo[letter];
        memo[letter] = [...folders, folder];
      } else {
        memo[letter] = [folder];
      }
      return memo;
    }, Object.entries([]));

    return this.turnIntoAvailableArray(results);
  }

  turnIntoAvailableArray(groupedFolders) {
    let result = [];
    for(const [letter, folders] of Object.entries(groupedFolders)) {
      result.push({
        name: letter,
        folders,
      });
    }
    return result;
  }

  getContainerStyle()
  {
    var windowWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
    //
    return { maxWidth: windowWidth + 'px'};
  }



  render() {
    // const classes = useStyles();
    const { currentFolder } = this.props;

    if (this.props.loading || !currentFolder) {
      return (<LoadingComponent />);
    }

    const groupedFolders = this.groupByLetter(currentFolder);

    return (
      <div className={(currentFolder.content && (hasFile(currentFolder))?"ellipsis":'')}>
        <div className="container-fluid" style={this.getContainerStyle()}>
          <h1 style={style.title}>
            {
              !this.props.selectionMode &&
                <>
                  {
                    (currentFolder.content && currentFolder.breadcrumb.length > 1) &&
                      <div
                        style={{
                          fontSize: '1.4rem',
                          cursor: 'pointer',
                          height: '100%',
                        }}
                        onClick={() => this.backButton()}
                        className={"d-flex justify-content-center align-items-center mr-2 icon-button"}
                      >
                        <i className="fa fa-angle-left"></i>
                      </div>
                  }

                  {
                    (currentFolder.content && currentFolder.breadcrumb.length <= 1) &&
                      <div
                        style={{
                          fontSize: '1.4rem',
                          cursor: 'pointer',
                          height: '100%',
                          minWidth: '40px',
                        }}
                        className={"d-flex justify-content-center align-items-center mr-2"}
                      >
                        <i className="fa fa-folder"></i>
                      </div>
                  }
                </>
            }
            {currentFolder.name}
          </h1>

          {
            (this.props.selectionMode && this.props.selectedFolders.length <= 0) &&
              <small className={"py-2 d-block"}>
                <em className={"text-muted"}>Séléctionner les éléments à archiver</em>
              </small>
          }

          {
            (this.props.selectionMode && this.props.selectedFolders.length > 0) &&
              <small className={"py-2 d-block"}>
                <em className={"text-muted"}>{this.props.selectedFolders.length} élément(s) séléctionné(s)</em>
              </small>
          }

          {
            /*
            (groupedFolders && hasFolder(currentFolder)) &&
              <div>
                {groupedFolders.map((letter, key) => (
                    <>
                      <div className="text-muted">
                        <strong>{letter.name}</strong>
                      </div>
                      <div className="row no-gutters explorer-folder-container">
                        {letter.folders.map((folder, key) => (
                          <div
                            className="col-auto"
                            key={key}
                          >
                            {
                              folder.type == 'folder' &&
                                <Folder folder={folder}/>
                            }
                          </div>
                        ))}
                      </div>
                    </>
                ))}
              </div>
          */
          }

          {
            (currentFolder.content && hasFolder(currentFolder)) &&
              <div className="row no-gutters explorer-folder-container">
                {
                  currentFolder.content.map((item, key) => (
                    <div
                      className="col-auto"
                      key={key}
                    >
                      {
                        item.type == 'folder' &&
                          <Folder folder={item}/>
                      }
                    </div>
                  ))
                }
              </div>
          }
          {
            (currentFolder.content && hasFile(currentFolder)) &&
                  <ul style={{listStyleType: 'none', margin: '0', padding: '0'}}>
                    {
                      currentFolder.content.map((item, key) => (
                        <li key={key}>
                          {
                            item.type == 'file' &&
                              <File file={item}/>
                          }
                        </li>
                      ))
                    }
                  </ul>
              }
              {
                (currentFolder.content && currentFolder.content.length === 0) &&
                  <div className="text-muted ml-2">
                    <em>Ce répertoire est vide</em>
                  </div>
              }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentFolder: state.explorer.currentFolder,
  loading: state.explorer.loading,
  selectedFolders: state.explorer.selectedFolders,
  selectionMode: state.explorer.selectionMode,
});

export default connect(mapStateToProps, { goToPath, unselectAll })(Explorer);
