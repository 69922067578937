import React from 'react';
import { confs } from '../../conf';

const { PRIMARY_BG } = confs;

const style = {
  h1 : {
    fontSize: '1.2rem',
  },
  h2: {
    fontSize: '1.1rem',
    color: PRIMARY_BG,
  },
  h3: {
    fontSize: '1rem',
    color: PRIMARY_BG,
  }
}

class CGU extends React.Component {
  render() {
    return (
      <div className={"px-3 py-3"}>
        <h1 style={style.h1}>
          1- Avis sur les données personnelles
        </h1>
        <p>
          Nous avons rassemblé dans notre Avis sur les données personnelles toutes les informations essentielles
          concernant le traitement par SOMESCA de vos données personnelles, ainsi que vos droits à cet égard.
        </p>
        <h2 style={style.h2}>
          1.1 Champ d'application et mises à jour du présent Avis sur les données personnelles
        </h2>
        <p>
          Le présent Avis sur les données personnelles s'applique :
        </p>
        <p>
          <ul>
            <li>
              À votre utilisation de ce portail Web et de l'ensemble des applications, services, produits et outils
              SOMESCA (ci-après appelés collectivement les « Services »), quelle que soit la manière dont vous
              utilisez ces Services ou y accédez, y compris depuis des appareils et applications mobiles.
            </li>
            <li>
              lorsqu'il y est fait explicitement référence à l'aide d'un lien ou d'une manière similaire. Nous
              pouvons modifier le présent Avis sur les données personnelles à tout moment en publiant sur ce
              portail Web la version modifiée, avec la date de son entrée en vigueur.
            </li>
          </ul>
        </p>
        <h2 style={style.h2}>1.2 Responsable du traitement</h2>
        <p>
          La société SOMESCA est responsable de la collecte et du traitement de vos données personnelles dans le
          cadre de la fourniture et de l'utilisation que vous faites des Services disponibles sur ce portail WEB.
        </p>
        <h2 style={style.h2}>1.3 Délégué à la protection des données et coordonnées</h2>
        <p>
          Nous avons désigné pour délégué à la protection des données pour qu'il supervise la protection de vos
          données personnelles, le Service Informatique de SOMESCA. Si vous avez des questions ou des
          réclamations sur le présent Avis sur les données personnelles ou la protection des données par SOMESCA
          en général, vous pouvez à tout moment contacter le délégué à la protection des données par courriel à :
          reclamation-rgpd@somesca.fr
        </p>
        <h2 style={style.h2}>1.4 Données personnelles recueillies et traitées</h2>
        <p>
          Nous recueillons des données personnelles vous concernant, lors de votre première connexion sur le
          Portail SOMESCA au travers des informations saisies sur le formulaires d'informations. Les information
          personnelles recueillies sont :
        </p>
        <p>
          <ul>
            <li>
              Les données permettant de vous identifier, telles que votre nom, prénom, courriel.
            </li>
            <li>
              Les données concernant votre société, telles la raison sociale, l'adresse, le pays, les numéros de
              téléphone fixe et portable.
            </li>
            <li>
              D'autres données que nous sommes tenus de collecter et de traiter, ou autorisés à le faire,
              conformément à la législation applicable pour vous authentifier ou vous identifier, ou pour vérifier
              les informations que nous collectons.
            </li>
            <li>
              Des informations sur vos connexions (dates et heures) afin d'établir des statistiques sur votre
              utilisation des services du Portail SOMESCA.
            </li>
          </ul>
        </p>
        <p>
          Nous n'utilisons pas de cookies, balises Web et technologies similaires pour recueillir des données lorsque
          vous utilisez nos Services sur le Portail SOMESCA, y compris sur les appareils mobiles et tablettes.
        </p>
        <h2 style={style.h2}>1.5 Finalités et base légale du traitement des données et catégories de destinataires</h2>
        <p>
          Nous traitons vos données personnelles en interne, à des fins diverses et principalement marketing, en
          conformité avec la réglementation en vigueur. Les données personnelles recueillies sont utilisées
          notamment pour fournir et améliorer nos Services, vous offrir une expérience personnalisée sur ce Portail
          Web, vous contacter au sujet de votre compte SOMESCA, de nos services, et vous proposer des publicités
          et des communications marketing personnalisées.
        </p>
        <p>
          Vos données personnelles sont exploitées au sein de la société SOMESCA et ne sont en aucun cas,
          vendues ou partagées avec des tiers, sauf, dans le cadre du respect de la réglementation en vigueur et
          des textes de Loi. Dans ces deux derniers cas, la société SOMESCA peut-être contrainte de fournir des
          données personnelles en sa possession afin de :
        </p>
        <p>
          <ul>
            <li>
              Participer à des procédures (y compris des procédures judiciaires) menées par les autorités
              publiques ou des organismes gouvernementaux, en particulier, dans le but de détecter des
              activités illégales, d'enquêter dessus et d'engager des poursuites judiciaires.
            </li>
            <li>
              Sur demande des services de maintien de l'ordre, des tribunaux, des autorités publiques, les
              organes intergouvernementaux ou supranationaux.
            </li>
            <li>
              Prévenir, détecter et limiter les activités illégales (par ex., fraude, blanchiment d'argent et
              financement d'activités terroristes).
            </li>
            <li>
              Garantir la sécurité des informations de nos Services.
            </li>
          </ul>
        </p>
        <h2 style={style.h2}>1.6 Transferts internationaux de données</h2>
        <p>
          De part les technologies utilisées sur le Portail SOMESCA, certains destinataires de vos données
          personnelles ne résident pas dans votre pays ou ont des bureaux dans des pays où la loi en matière de
          protection des données peut offrir un niveau de protection différent de celle de votre pays. Lors du
          transfert de données personnelles à ces destinataires, nous prenons les mesures de protection
          appropriées afin de garantir la meilleure sécurité de celles-ci.
        </p>
        <h2 style={style.h2}>1.7 Durée de stockage et effacement</h2>
        <p>
          Vos données personnelles sont stockées par nous conformément aux lois en matière de protection des
          données applicables dans la mesure nécessaire aux finalités du traitement établies dans le présent Avis
          sur les données personnelles (voir la section "1.5 Finalités et base légale du traitement des données et
          catégories de destinataires" pour en savoir plus sur les finalités du traitement). Par la suite, nous
          supprimerons vos données personnelles conformément à notre règlement en matière de conservation et
          de suppression des données ou prendrons des mesures pour anonymiser les données de manière
          appropriée, à moins que la loi nous impose de conserver vos données personnelles plus longtemps (par
          ex., à des fins fiscales, comptables ou d'audit). En Europe, les périodes de conservation sont
          généralement de 6 à 10 ans (par ex., pour les contrats, les notifications et les lettres commerciales). Dans
          la mesure requise ou autorisée par la loi, nous limitons le traitement de vos données plutôt que de les
          supprimer (par ex., en restreignant l'accès à ces données). C'est le cas notamment lorsque nous sommes
          susceptibles d'avoir encore besoin des données pour exécuter le contrat, pour introduire une procédure
          en justice ou pour nous défendre contre une telle procédure. Dans ces cas-là, la durée de la limitation du
          traitement dépend des périodes de limitation ou de conservation réglementaires respectives. Les
          données seront supprimées à l'expiration des périodes de limitation ou de conservation pertinentes.
        </p>
        <p>
          La durée de conservation des données personnelles peut varier selon les Services fournis et nos
          obligations légales conformément à la loi nationale applicable. Les facteurs qui influent généralement sur
          la durée de conservation sont les suivants :
        </p>
        <p>
          <ul>
            <li>
              Données nécessaires pour la fourniture de nos Services
              Cela inclut, par exemple, l'exécution de l'accord conclu avec vous, le maintien et l'amélioration
              des performances de nos produits, le maintien de la sécurité de nos systèmes et la tenue des
              documents professionnels appropriés. La plupart de nos périodes de conservation sont
              déterminées sur la base de cette règle générale.
            </li>
            <li>
              Catégories spéciales de données personnelles
              Si nous stockons des catégories particulières de données personnelles, une période de
              conservation plus courte est généralement appropriée.
            </li>
            <li>
              Traitement des données personnelles basé sur le consentement
              Si nous traitons des données personnelles sur la base de votre consentement (y compris votre
              consentement à un stockage prolongé), nous les conservons aussi longtemps que nécessaire
              pour les traiter conformément à votre consentement.
            </li>
            <li>
              Obligations réglementaires, contractuelles ou similaires autres
              Nous pouvons être tenus de conserver vos données par exemple conformément à des lois ou
              injonctions officielles. Nous pouvons également être amenés à conserver des données
              personnelles liées à des litiges en cours ou futurs. Les données personnelles contenues dans
              des contrats, des notifications et des lettres commerciales peuvent faire l'objet d'obligations
              légales en matière de stockage conformément à la loi nationale.
            </li>
          </ul>
        </p>
        <h2 style={style.h2}>1.8 Vos droits en tant que personne concernée</h2>
        <p>
          Sous réserve des restrictions possibles prévues par la loi nationale, en tant que personne concernée, vous
          bénéficiez de droits d'accès, de rectification, d'effacement, de limitation du traitement et de portabilité
          des données en ce qui concerne vos données personnelles. Vous pouvez également retirer votre
          consentement et vous opposer au traitement que nous faisons de vos données personnelles sur la base
          d'intérêts légitimes. Vous pouvez également introduire une réclamation auprès d'une autorité de
          contrôle.
        </p>
        <p>
          Vos droits en détail :
        </p>
        <p>
          <ul>
            <li>
              Vous pouvez retirer votre consentement au traitement que nous faisons de vos données
              personnelles à tout moment. En conséquence, nous ne pourrons plus traiter vos données
              personnelles sur la base de ce consentement par la suite. Le retrait du consentement est sans
              effet sur la légalité du traitement basé sur le consentement avant son retrait.
            </li>
            <li>
              Vous avez le droit d'obtenir un accès aux données personnelles vous concernant que nous
              traitons. En particulier, vous pouvez demander des informations sur les finalités du traitement,
              les catégories de données personnelles concernées, les catégories de destinataires à qui vos
              données personnelles ont été ou seront divulguées, la durée prévue de conservation des
              données personnelles, le droit de demander la rectification ou la suppression de données
              personnelles ou la limitation du traitement des données personnelles ainsi que de vous
              opposer à un tel traitement, le droit d'introduire une réclamation auprès d'une autorité de
              contrôle, ainsi que toute information disponible concernant la source des données
              personnelles (lorsqu'elles ne sont pas recueillies auprès de vous). Votre droit d'accès peut être
              limité par la loi nationale.
            </li>
            <li>
              Vous avez le droit d'obtenir de notre part, sans retard indu, la rectification de données
              personnelles inexactes vous concernant. Compte tenu des finalités du traitement, vous avez le
              droit de compléter des données personnelles incomplètes, notamment en effectuant une
              déclaration supplémentaire.
            </li>
            <li>
              Vous avez le droit d'obtenir de notre part l'effacement de données personnelles vous
              concernant, à moins que leur traitement ne soit nécessaire pour l'exercice du droit de liberté
              d'expression et d'information, à des fins de respect d'une obligation légale, pour des raisons
              d'intérêt public ou pour la constatation, l'exercice ou la défense d'un droit en justice. Le droit à
              l'effacement peut être limité par la loi nationale.
            </li>
            <li>
              Vous avez le droit d'obtenir de notre part la limitation du traitement de vos données dans la
              mesure ou :
              <p>
                <ul>
                  <li>
                    vous contestez l'exactitude des données.
                  </li>
                  <li>
                    le traitement est illégal, mais vous vous opposez à l'effacement des données
                    personnelles.
                  </li>
                  <li>
                    nous n'avons plus besoin des données, mais vous en avez besoin pour constater, exercer
                    ou défendre un droit en justice.
                  </li>
                  <li>
                    vous vous êtes opposé au traitement.
                  </li>
                </ul>
              </p>
            </li>
            <li>
              Vous avez le droit de recevoir les données personnelles vous concernant que vous nous avez
              fournies dans un format structuré, couramment utilisé et lisible par machine, ainsi que de
              transmettre ces données à un autre responsable du traitement (« droit à la portabilité des
              données »).
            </li>
            <li>
              Vous avez le droit d'introduire une réclamation auprès d'une autorité de contrôle. En règle
              générale, vous pouvez contacter l'autorité de contrôle de votre lieu de résidence habituel, de
              votre lieu de travail ou du siège social du responsable du traitement.
            </li>
          </ul>
        </p>
        <p>
          Si vos données personnelles sont traitées sur la base d'intérêts légitimes, vous avez le droit de vous
          opposer à leur traitement pour des motifs liés à votre situation particulière. Si nous traitons vos données
          personnelles à des fins de marketing direct, vous avez le droit de vous opposer à tout moment au
          traitement de vos données personnelles.
        </p>
        <p>
          L'exercice des droits des personnes concernées ci-dessus (par ex., le droit d'accès ou à l'effacement) est
          généralement gratuit. Lorsque les demandes sont manifestement non fondées ou excessives, notamment
          en raison de leur caractère répétitif, nous pouvons facturer des frais appropriés (correspondant au
          maximum à nos coûts réels) conformément aux réglementations applicables ou refuser de traiter la
          demande.
        </p>
        <p>
          Toutes les demandes relatives à la section "1.8 Vos droits en tant que personne concernée", doivent se
          faire par courriel à : reclamation-rgpd@somesca.fr
        </p>
        <h2 style={style.h2}>1.9 Sécurité des données</h2>
        <p>
          Nous protégeons vos données personnelles à l'aide de mesures de sécurité techniques et administratives
          pour réduire les risques de perte, d'utilisation abusive, d'accès non autorisé, de divulgation et de
          modification non autorisées. À cette fin, nous utilisons le cryptage de données personnelles, en sus des
          méthodes de protection mises en place par l'hébergeur de notre Portail SOMESCA, et de celui du Cloud
          utilisé par notre système.
        </p>
        <h2 style={style.h2}>1.10 Autres informations importantes concernant la protection des données
        Données personnelles concernant des tiers</h2>
        <p>
          Si vous nous fournissez des données personnelles relatives à une autre personne, vous devez obtenir le
          consentement de celle-ci, à moins que la divulgation de ces données ne soit autorisée d'une quelconque
          autre manière par la loi. Vous devez informer l'autre personne de la manière dont nous traitons les
          données personnelles au titre de notre Avis sur les données personnelles.
        </p>
        <h2 style={style.h2}>1.11 Nous contacter</h2>
        <p>
          Pour toute question ou réclamation concernant le présent Avis sur les données personnelles, vous pouvez
          nous contacter :
        </p>
        <p>
          <ul>
            <li>
              Par courrier à l'adresse : Société SOMESCA, 6 rue Jean JAURÈS - 92800 PUTEAUX - FRANCE
            </li>
            <li>
              Par courriel : reclamation-rgpd@somesca.fr
            </li>
          </ul>
        </p>
        <p>
          Votre droit de déposer une plainte auprès d’une autorité de contrôle demeure inchangé.
        </p>
        <h1 style={style.h1}>2- Conditions générales d'utilisation (CGU)</h1>
        <h2 style={style.h2}>2.1 Préambule</h2>
        <p>
          Il est fortement recommandé de lire attentivement l'intégralité des présentes Conditions Générales
          d'Utilisation avant de consulter ce portail SOMESCA (ci-après, le « Site »).
          Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») ont pour objet de déterminer les
          conditions d’accès et les règles d’utilisation du Site.
        </p>
        <p>
          L’accès au Site est subordonné au respect des CGU. L’accès au Site est strictement réservé à l’utilisateur
          (ci-après, « l’Utilisateur ») tel que défini dans les CGU.
        </p>
        <p>
          La société SOMESCA se réserve le droit de modifier ou de mettre à jour à tout moment, unilatéralement,
          les CGU au gré des changements ou additions effectués, afin notamment de se conformer à toutes
          évolutions légales, jurisprudentielles, éditoriales et/ou techniques ainsi qu’aux éventuelles modifications
          des conditions générales d’utilisation des services de l’Hébergeur ou de l'Hébergeur Cloud.
        </p>
        <p>
          L'Utilisateur doit consulter régulièrement la dernière version des CGU disponible en permanence sur le
          Site.
        </p>
        <h2 style={style.h2}>2.2 Définitions</h2>
        <p>
          Au sein des CGU, les mots ou expressions commençant avec une majuscule auront la signification
          suivante :
        </p>
        <p>
          <ul>
            <li>
              Contribution(s) : désigne(nt) l’ensemble des commentaires et/ou contenus que les Utilisateurs
              ont la possibilité de publier sur le Site par courriel.
            </li>
            <li>
              Éditeur : désigne la société SOMESCA.
            </li>
            <li>
              Hébergeur : désigne OVH.com
            </li>
            <li>
              Hébergeur Cloud : désigne la société pCloud
            </li>
            <li>
              Information(s) : désigne(nt) l’ensemble des informations et publications accessibles sur le Site.
            </li>
            <li>
              Site : désigne le site proposé par l’Éditeur sur Internet.
            </li>
            <li>
              Utilisateur(s) : désigne(nt) toute personne ayant accès au Site quel que soit le lieu où elle se
              trouve et les modalités de connexion.
            </li>
          </ul>
        </p>
        <h2 style={style.h2}>2.3 Objet du Site</h2>
        <p>
          Le Site a pour objet de mettre à la disposition des Utilisateurs, des documents établis par l' éditeur en
          consultation et/ou téléchargement. Ils ont également la possibilité d'envoyer des courriels à l'éditeur
          depuis le site.
        </p>
        <p>
          Toute utilisation du Site se fera dans le respect des CGU.
        </p>
        <h2 style={style.h2}>2.4 Accès et utilisation du Site</h2>
        <h3 style={style.h3}>2.4.1 Accès au Site</h3>
        <p>
          L’accès au Site est possible à partir d’un ordinateur, d’une tablette ou d’un smartphone connecté à
          un réseau de télécommunication selon les protocoles de communication utilisés sur le réseau
          Internet.
        </p>
        <p>
          L'éditeur accorde à l’Utilisateur un droit de consultation, d’utilisation et d’accès aux Informations du
          Site.
        </p>
        <h3 style={style.h3}>2.4.2 Coût d'accès</h3>
        <p>
          L’accès au Site est gratuit et ne fait pas l’objet d’un abonnement.
        </p>
        <p>
          Tous les logiciels et matériels nécessaires à l’utilisation ou au fonctionnement du Site, l’accès à
          Internet ou les frais de communication sont à la charge exclusive de l’Utilisateur en dehors de son
          lieu de travail.
        </p>
        <p>
          Chaque Utilisateur est entièrement responsable de ses données de connexion le concernant.
        </p>
        <p>
          Sauf preuve contraire, toute connexion au Site, ou transmission de données effectuées à partir des
          informations de connexion de l’Utilisateur, sera réputée avoir été effectuée par ce dernier.
        </p>
        <h3 style={style.h3}>2.4.3 Durée d'accessibilité</h3>
        <p>
          L’accès de l’Utilisateur au Site est effectué pour une durée illimitée, sans préjudice de la faculté
          pour l’Utilisateur ou l'éditeur de résilier unilatéralement, à tout moment, sans préavis, ni motif, ou
          indemnité.
        </p>
        <p>
          L’Utilisateur reconnaît, qu’en cas de violation des dispositions législatives et/ou des CGU, l'éditeur
          peut bloquer et/ou résilier l’accès au Site, sans notification préalable, avec effet immédiat.
        </p>
        <h3 style={style.h3}>2.4.4 Utilisation du Site</h3>
        <p>
          L'utilisation du Site est réservée à un usage strictement personnel, sauf encarts spécifiés par
          l'éditeur.
        </p>
        <p>
          L’Utilisateur s'interdit notamment, sans que cette liste soit limitative :
        </p>
        <p>
          <ul>
            <li>
              d'utiliser le Site à des fins de commerce et d'une manière générale de proposer des produits
              et des services le rémunérant de manière directe ou indirecte.
            </li>
            <li>
              de « revendre » ou de mettre à disposition d'un tiers et/ou des autres Utilisateurs, à titre
              onéreux et/ou gratuit, le contenu du Site.
            </li>
            <li>
              d'utiliser le Site en vue d'adresser, sous quelque forme que ce soit, de la publicité ou des
              éléments à caractère promotionnel non sollicités.
            </li>
            <li>
              de diriger les internautes indirectement ou directement, notamment par des liens
              hypertextes, vers d'autres sites Internet qui seraient susceptibles de ne pas respecter la
              législation française ou les stipulations des CGU.
            </li>
          </ul>
        </p>
        <p>
          Afin de permettre le bon fonctionnement du Site, certaines contraintes techniques doivent être
          respectées par les Utilisateurs, celles-ci étant susceptibles d'être modifiées.
        </p>
        <p>
          L’Utilisateur est tenu de respecter les indications techniques suivantes afin de pouvoir accéder au
          Site et d’utiliser les services proposés de manière optimale. Il s'interdit toute action susceptible
          d'entraver ou de perturber le bon fonctionnement ou l’accessibilité technique du Site. La
          configuration minimale requise pour l'accès au Site est la suivante :
        </p>
        <p>
          <ul>
            <li>
              Ecran : 1024 X 768 minimum
            </li>
            <li>
              Navigateurs minimum : IE 7, Safari 2, Firefox 2, Mozilla Firefox
            </li>
            <li>
              L’Utilisateur s’engage à accéder au Site en utilisant un matériel ne contenant pas de virus.
            </li>
          </ul>
        </p>
        <p>
          L'éditeur ne garantit pas que les serveurs de l'hébergeur qui héberge le Site ou celui de l'hébergeur
          Cloud, sont exempts de virus et autres composants dangereux. Il appartient donc à l’Utilisateur de
          prendre toutes les mesures appropriées pour protéger ses propres données et/ou logiciels de la
          contamination par d'éventuels virus circulant sur le réseau Internet.
        </p>
        <p>
          L’Utilisateur accepte le fait que l'éditeur ne pourra, en aucun cas, être tenue pour responsable des
          dommages fortuits, matériels et/ou immatériels, directs et/ou indirects qui pourraient résulter de
          l’accès ou de l’utilisation du Site, y compris l’inaccessibilité, les pertes de données, détériorations,
          destructions ou virus qui pourraient affecter l’équipement informatique de l’Utilisateur, et/ou de la
          présence de virus sur son Site.
        </p>
        <p>
          Par ailleurs, tout matériel téléchargé et/ou obtenu, de quelque manière que ce soit, lors de
          l'utilisation du Site, l'est aux risques et périls de l’Utilisateur. L'éditeur n’est aucunement
          responsable des dommages et/ou pertes de données subis par son ordinateur.
        </p>
        <p>
          Les informations publiées par l'éditeur et contenues sur le Site sont non-contractuelles et fournies à
          titre informatif, sans aucune obligation de la part de l'éditeur qui peut les modifier sans préavis.
        </p>
        <p>
          L'éditeur s'efforce de fournir sur le Site des informations aussi précises que possible. Toutefois, il ne
          pourra être tenue pour responsable des omissions, des inexactitudes et des carences dans la mise à
          jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
        </p>
        <p>
          L’Utilisateur est invité à vérifier toute information diffusée sur le Site.
        </p>
        <p>
          Des espaces interactifs sont à la disposition des Utilisateurs. Toutefois, l'éditeur se réserve le droit
          de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui
          contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la
          protection des données. Le cas échéant, l'éditeur se réserve également la possibilité de mettre en
          cause la responsabilité civile et/ou pénale de l'Utilisateur, notamment en cas de message à
          caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte,
            photographie...).
        </p>
        <h2 style={style.h2}>2.5 Disponibilité du Site</h2>
        <p>
          Le Site est accessible 24 heures sur 24, 7 jours sur 7, sauf cas de force majeure ou d’événement hors du
          contrôle de l'éditeur.
        </p>
        <p>
          L'éditeur se réserve le droit de suspendre, d'interrompre ou de limiter, sans avis préalable, l'accès à tout
          ou partie du Site, notamment pour des opérations de maintenance et de mises à jour nécessaires au bon
          fonctionnement du Site et des matériels afférents, ou pour toute autre raison, notamment technique.
        </p>
        <p>
          L'éditeur n’est tenue à aucune obligation de résultat concernant la disponibilité du Site et l’accessibilité
          technique au Site. Il n’est en aucun cas responsable des interruptions et des conséquences qui peuvent en
          découler pour l'Utilisateur notamment lorsque les interruptions proviennent de celles de l’Hébergeur ou
          de l'Hébergeur Cloud pour leurs besoins de maintenance ou autres.
        </p>
        <p>
          L’Utilisateur est informé que l'éditeur peut mettre fin ou modifier les caractéristiques du Site, à tout
          moment, sans préavis et sans que l’Utilisateur dispose d’un recours à l’encontre de celui-ci.
        </p>
        <h2 style={style.h2}>2.6 Hébergement du Site</h2>
        <p>
          Les Utilisateurs sont expressément informés que les contenus publiés sur le Site sont hébergés et stockés
          sur les serveurs de la société OVH.com et pCloud.
        </p>
        <h2 style={style.h2}>2.7 Contributions</h2>
        <p>
          L'éditeur se réserve la possibilité de supprimer toute Contribution des Utilisateurs qui violeraient les CGU
          ou qui serait susceptible d’engager en tout ou partie la responsabilité de celui-ci et/ou de l'Hébergeur ou
          l'Hébergeur Cloud.
        </p>
        <p>
          L’Utilisateur est responsable de toute Contribution qu’il affiche via le Site ainsi que de leurs
          conséquences. La Contribution que l’Utilisateur soumet, publie ou affiche devra se faire dans le respect
          des CGU.
        </p>
        <p>
          L’Utilisateur s’engage à soumettre à l'éditeur des informations et des contenus véridiques, faisant
          apparaître la source et l’auteur de manière distincte dans le cas d’emprunt à une œuvre préexistante.
        </p>
        <p>
          Par ailleurs, les Contributions ne doivent pas :
        </p>
        <p>
          <ul>
            <li>
              Porter atteinte aux droits de propriété intellectuelle.
            </li>
            <li>
              Être de nature discriminatoire ou de nature à inciter à la violence ou à la haine, pour des
              raisons d'origine ethnique, politiques, d'orientation sexuelle etc.
            </li>
            <li>
              Comporter des contenus obscènes, pornographiques, etc.
            </li>
            <li>
              Porter atteinte à l’intégrité et/ou la sécurité du Site et notamment comporter des virus,
              cheval de Trois, worms, bombe à retardement, cancelbots ou tout autre programme
              informatique destiné à endommager, à intercepter clandestinement tout système
              informatique, données ou informations nominatives.
            </li>
            <li>
              Être susceptibles d'engager la responsabilité de l'éditeur et/ou de l’Hébergeur et de
              l'Hébergeur Cloud en proposant des liens hypertextes renvoyant vers des sites ou des
              contenus illicites, contrefaisants, ou plus largement, vers des contenus susceptibles
              d'enfreindre les lois ou les règlements en vigueur ou vers des sites avec restriction d’accès
              sans autorisation des titulaires de droit sur le contenu.
            </li>
          </ul>
        </p>
        <p>
          L’Utilisateur déclare être seul et unique titulaire de tout éventuel droit d'auteur sur les Contributions et
          garantit à l'éditeur et/ou à l’Hébergeur et l'Hébergeur Cloud, la jouissance libre et entière de toutes
          servitudes des droits faisant l'objet des CGU, contre tous troubles, revendications et évictions
          quelconques.
        </p>
        <p>
          L’Utilisateur garantit notamment qu'aucun litige ou procès n'est en cours ou sur le point d'être intenté
          quant aux Contributions mettant en cause les droits d'auteur.
        </p>
        <h2 style={style.h2}>2.8 Propriété intellectuelle</h2>
        <p>
          De manière générale, l’Utilisateur s’interdit de porter atteinte aux droits de propriété intellectuelle (droit
            d’auteur, droits voisins, droit sui generis du producteur de bases de données, droit des marques, noms de
            domaine…) de l'éditeur et/ou de l’Hébergeur, et/ou de l’Hébergeur Cloud, et/ou de tout tiers.
        </p>
        <p>
          L'éditeur est titulaire de tous les droits de propriété intellectuelle tant sur la structure que sur le contenu
          du Site (textes, logos, images, éléments sonores, logiciels, icônes, mise en page, base de données…) ou a
          acquis régulièrement les droits permettant l'exploitation de la structure et du contenu du Site, sans
          aucune limitation.
        </p>
        <p>
          L'éditeur concède à l’Utilisateur, le droit d’utiliser le Site pour ses besoins strictement privés y compris
          professionnelles, à l’exclusion de toute utilisation lucrative.
        </p>
        <p>
          Ainsi, il est interdit à l’Utilisateur notamment de copier, reproduire, représenter, modifier et/ou exploiter,
          transférer de quelque façon que ce soit et à quelque fin que ce soit, tout ou partie de la structure et du
          contenu du Site, sauf en cas d’autorisation expresse, préalable et écrite de l'éditeur.
        </p>
        <p>
          Le téléchargement par l’Utilisateur de certains éléments, notamment textes, photographies peut être
          autorisé par l'éditeur. Cette fonctionnalité est à l’entière discrétion de l'éditeur, qui peut la modifier ou la
          supprimer à tout moment. L’Utilisateur s’engage par conséquent à ne pas émettre de contestation contre
          l'éditeur à ce titre. De plus, la constatation d’un téléchargement abusif pourra entraîner de la part de
          l'éditeur et/ou de tout tiers concerné la prise de sanctions qui se révéleraient appropriées, notamment en
          cas de violation des droits de propriété intellectuelle.
        </p>
        <p>
          Les marques et logos reproduits sur le Site sont déposés par les sociétés qui en sont titulaires. Toute
          reproduction des noms ou logos, par quelque moyen que ce soit, sans autorisation préalable du titulaire
          concerné est interdite.
        </p>
        <p>
          Le non-respect de ces interdictions peut notamment constituer un acte de contrefaçon et/ou de
          concurrence déloyale et parasitaire engageant la responsabilité civile et/ou pénale de l’Utilisateur.
        </p>
        <h2 style={style.h2}>2.9 Liens hypertextes</h2>
        <p>
          A l’exception de sites diffusant notamment des informations et/ou contenus ayant un caractère illégal
          et/ou notamment à caractère politique, religieux, pornographique, un Utilisateur peut créer un lien
          hypertexte sur un site vers le Site.
        </p>
        <p>
          En cas d’utilisation de ces sites, l’Utilisateur est invité à se reporter aux Conditions Générales d’Utilisation
          du site concerné. Au titre de la présente autorisation, l'éditeur se réserve toutefois un droit d’opposition
          et se dégage de toute responsabilité quant aux informations contenues dans ces sites qui pourraient être
          liés au sien par un lien hypertexte ou autre moyen.
        </p>
        <p>
          A contrario, des liens hypertextes contenus sur le Site peuvent renvoyer vers d'autres sites Internet par
          tout moyen.
        </p>
        <p>
          Dans la mesure où l'éditeur ne peut contrôler ces sites et ces sources externes, il ne peut être tenue pour
          responsable de la mise à disposition de ces sites et sources externes, et ne peut supporter aucune
          responsabilité quant aux contenus, publicités, produits, services ou tout autre matériel, disponibles sur ou
          à partir de ces sites ou sources externes.
        </p>
        <p>
          De plus, l'éditeur ne pourra être tenue pour responsable de tout dommage avéré ou allégué consécutif ou
          en relation avec l'utilisation ou avec le fait d'avoir fait confiance aux contenus, à des biens ou des services
          disponibles sur ces sites ou sources externes.
        </p>
        <p>
          Enfin, dans la limite autorisée par la loi, l'éditeur ne saura être tenue pour responsable dans le cas où le
          contenu desdits autres sites contreviendrait aux dispositions légales et réglementaires en vigueur.
        </p>
        <h2 style={style.h2}>2.10 Garanties/Responsabilités</h2>
        <p>
          L’Utilisateur s’engage, lors de l’utilisation qu’il fait du Site, à ne pas contrevenir aux dispositions
          législatives et réglementaires en vigueur, aux bons usages d’Internet et aux CGU.
          De manière générale, l'éditeur se dégage de toute responsabilité en cas d’utilisation non-conforme aux
          CGU et de ses services.
        </p>
        <p>
          L’Utilisateur est informé que toute violation des stipulations des CGU est susceptible d'entraîner des
          poursuites judiciaires et des sanctions à son encontre.
        </p>
        <p>
          Dans les mêmes conditions, l’Utilisateur s’engage également à respecter les règles et usages internes
          relatifs à l’utilisation des systèmes d’information et réseaux de l'éditeur.
        </p>
        <p>
          L’Utilisateur s'engage à agir avec diligence pour répondre à toute réclamation.
        </p>
        <p>
          L’Utilisateur est responsable envers l'éditeur et/ou les tiers de tout préjudice matériel et/ou immatériel,
          direct et/ou indirect de quelque nature que ce soit causé par l’Utilisateur, et/ou ses préposés en cas
          d’utilisation non-conforme aux CGU.
        </p>
        <p>
          L’Utilisateur garantit l'éditeur, ainsi que ses sociétés mères, sœurs ou affiliées, ses représentants, ses
          salariés, ses partenaires, l’Hébergeur, l'Hébergeur Cloud, contre toute demande, réclamation,
          revendication et/ou recours de toute sorte, résultant de toute violation desdites stipulations.
        </p>
        <p>
          L’Utilisateur les tiendra indemnisés, à tout moment et à première demande, contre tout dommage et/ou
          contre toute demande, action, plainte émanant de tiers résultant de toute violation desdites stipulations.
        </p>
        <p>
          Cette garantie couvre tant les dommages et intérêts qui seraient éventuellement versés, quelle que soit
          leur origine directe ou indirecte, tels que notamment les honoraires d'avocat, frais d’expertise, frais de
          justice.
        </p>
        <p>
          L'éditeur s'engage à faire ses meilleurs efforts afin de mettre en œuvre des mesures techniques et
          d'organisation pour protéger les Informations circulant sur le Site.
        </p>
        <p>
          L’Utilisateur se déclare, néanmoins, parfaitement informé que les données ne sont pas protégées contre
          toute forme d'intrusion y compris par voie de piratage.
        </p>
        <p>
          L’Utilisateur reconnaît qu’il est impossible de garantir une sécurité totale aux données transmises. En
          conséquence, l'éditeur ne pourra être tenue responsable des incidents qui pourraient découler de cette
          transmission.
        </p>
        <p>
          Il appartient à l’Utilisateur de mettre en œuvre tous moyens utiles aux fins de préserver la confidentialité
          des données transmises.
        </p>
        <p>
          Chaque Utilisateur du Site s'engage expressément :
        </p>
        <p>
          <ul>
            <li>
              À ne pas utiliser de logiciels ou de procédés destiné à copier les Informations du Site sans
              l'autorisation expresse et préalable de l'éditeur.
            </li>
            <li>
              À renoncer expressément à utiliser des logiciels ou dispositifs susceptibles de perturber le
              bon fonctionnement du Site, ni à engager d'action de nature à imposer une charge
              disproportionnée pour les infrastructures de l'éditeur.
            </li>
            <li>
              À ne pas altérer ou modifier voire créer des œuvres dérivées à partir des Informations du
              Site sans le consentement exprès et préalable de l'éditeur.
            </li>
            <li>
              À ne procéder à de courtes citations, analyses et reproductions destinées à des revues de
              presse ou autres utilisations expressément autorisées par la loi que dans les limites et
              conditions fixées par ces dernières et sous réserve notamment de citer le nom des auteurs
              et la source.
            </li>
            <li>
              À ne pas extraire ou réutiliser, y compris à des fins privées, sans autorisation écrite et
              préalable de l'éditeur, une partie substantielle ou non du contenu des bases de données et
              archives constituées par le Site.
            </li>
            <li>
              À ne pas collecter des informations sur des tiers, y compris les adresses électroniques, afin
              de les utiliser pour l’envoi de sollicitations commerciales ou équivalentes, ou de les intégrer
              au sein d’un service de référencement ou équivalent, gratuit ou payant, ou encore afin
              d’effectuer une veille concurrentielle.
            </li>
            <li>
              À ne pas mettre en place des systèmes susceptibles ou de nature à pirater le Site en tout
              ou partie, ou de nature à violer les CGU.
            </li>
            <li>
              À informer l'éditeur dès la connaissance d'une atteinte quelle qu’elle soit (notamment aux
              droits de propriété intellectuelle de celui-ci) en particulier de toute utilisation illicite ou non
              contractuelle des Informations du Site et ce, quel que soit le mode de diffusion utilisé.
            </li>
          </ul>
        </p>
        <p>
          L’Utilisateur qui publie une Contribution sur le Site reconnaît être personnellement responsable, tant visà-vis des tiers que de l'éditeur, en cas de violation des dispositions législatives ou réglementaires
          applicables et garantit, en conséquence, l'éditeur contre tout trouble, revendication et éviction
          quelconque.
        </p>
        <h2 style={style.h2}>2.11 Contact</h2>
        <p>
          Pour toute question relative au fonctionnement du Site et aux CGU, vous pouvez nous contacter par
          courrier postal, à l’adresse suivante :
        </p>
        <p style={{marginLeft: '1rem'}}>
          Société SOMESCA <br/>
          6, rue Jean JAURÈS <br/>
          92800 PUTEAUX <br/>
        </p>
        <h2 style={style.h2}>2.12 Nullité d'une clause</h2>
        <p>
          La nullité en tout ou partie d’une ou de plusieurs stipulations des CGU, aux termes d'une disposition
          légale ou réglementaire ou d'une décision de justice devenue définitive, n'entraîne pas la nullité des
          autres stipulations ou de la partie de la disposition non entachées de nullité.
        </p>
        <h2 style={style.h2}>2.13 Droit applicable - Règlement des litiges</h2>
        <p>
          Les CGU sont soumises au droit français.
        </p>
        <p>
          En cas de différends relatifs à l’interprétation, la validité ou l’exécution des CGU, l’Utilisateur et SNCF
          conviennent de déployer leurs meilleurs efforts afin de régler à l’amiable le litige.
        </p>
        <p>
          Dans le cas où un accord amiable ne serait pas trouvé, tout éventuel litige se rapportant à l’interprétation
          ou l’exécution des CGU sera soumis à la compétence des tribunaux de Puteaux.
        </p>
      </div>
    );
  }
}

export default CGU;
