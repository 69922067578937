import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

class DropdownItem extends React.Component {

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
      let container = this.dditem.parentNode.parentNode;
      // remove show from container
      container.classList.remove('show');

    }
  }

  render() {
    const { label, icon } = this.props;

    if (this.props.onClick) {
      return (
        <div
          onClick={(e) => this.onClick(e)}
          className={"dropdown-item-button"}
          ref={dditem => this.dditem = dditem}
        >
          {icon && <i className={"fa mr-2 fa-"+icon}></i>}
          {label}
        </div>
      );
    }

    if (this.props.to) {
      return (
        <Link
          className={"dropdown-item-button"}
          ref={dditem => this.dditem = dditem}
          to={this.props.to}
          replace={this.props.replace}
         >
          {icon && <i className={"fa mr-2 fa-"+icon}></i>}
          {label}
        </Link>
      );
    }

  }
}

export default DropdownItem;
