import React from 'react';
import Button from '../Button/Button';
import { toggleSelectionMode } from '../Explorer/actions';
import { connect } from 'react-redux';
import { hasFolder } from '../../utils';

const style = {
  toolbar: {
    padding: '.5rem 1rem',
    display: 'flex',
    justifyContent: 'center',
  },
}

class Toolbar extends React.Component {
  state = {};

  render() {
    const { currentFolder } = this.props;
    return (
      <div style={style.toolbar}>
        {
          (currentFolder && currentFolder.content) &&
            <>
              {
                !this.props.selectionMode &&
                 <>
                  <Button mode={"toggle"} toggled={this.props.selectionMode} onClick={() => this.props.toggleSelectionMode()}>
                    {
                      <span style={{color: '#0076FF'}}><em>" Je souhaite créer une archive "</em></span>
                    }
                  </Button>
                </>
              }
              {
                this.props.selectionMode &&
                  <div style={{color: '#0076ff', padding: '.4rem 0rem'}}>Création d'une archive</div>
              }
            </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectionMode: state.explorer.selectionMode,
  currentFolder: state.explorer.currentFolder,
})

export default connect(mapStateToProps, { toggleSelectionMode })(Toolbar);
