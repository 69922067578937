import React from 'react';
import { connect } from 'react-redux';
import { goToPath } from '../Explorer/actions';

const style = {
  breadcrumb: {
    width: '100%',
    padding: '.5rem 1rem',
    fontSize: '.9rem',
  }
}

class Breadcrumb extends React.Component {
  state = {
    items: [],
  }
  static getDerivedStateFromProps(props, state) {
    if (state.items != props.breadcrumb && props.breadcrumb) {
      return {
        items: props.breadcrumb
      }
    }
    return null;
  }

  backButton() {
    if (this.props.selectionMode) return;
    //
    this.props.goToPath(this.props.currentFolder.previous);
  }

  render() {
    const { state } = this;
    return (
      <div style={style.breadcrumb} className={"d-flex"}>
        <div>
          {
            state.items.map((item) => (
              <span key={item.path}>
                {(item.active || this.props.selectionMode) && <span key={item.path}> {item.title} {(this.props.selectionMode && !item.active) && <i className="fa fa-angle-right"></i>} </span>}
                {(!item.active && !this.props.selectionMode) && <span key={item.path}> <a onClick={() => this.props.goToPath(item.path)} href="#"> {item.title} </a> <i className="fa fa-angle-right"></i> </span>}
              </span>
            ))
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectionMode: state.explorer.selectionMode,
  currentFolder: state.explorer.currentFolder,
});

export default connect(mapStateToProps, { goToPath })(Breadcrumb);
