import axios from 'axios';
import qs from 'qs';
import { confs } from '../../conf';

const { URL_API } = confs;

export const downloads = {
  fetchDownloads: (params) => axios.get(URL_API+'/downloads', {params})
    .then(res => res.data.data),
  downloadFile: (params) => axios.post(URL_API+'/downloads', qs.stringify(params))
    .then(res => res.data.data),
};
