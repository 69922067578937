import React from 'react';
import DownloadForm from '../components/Download/DownloadForm';
import history from '../history';
import { confs } from '../conf';

const { PRIMARY_BG } = confs;

const style = {
  header: {
    background: PRIMARY_BG,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    width: '100%',
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.2)',
    color: 'white',
    fontSize: '1.5rem',
    padding: '1rem'
  }
}

class Download extends React.Component {
  render() {
    return (
      <div>
        <header style={style.header}>
          <div style={{fontSize: '1rem'}}>
            <div className="icon-button" onClick={() => history.replace('/')}>
              <i className="fa fa-arrow-left"></i>
            </div>
          </div>
          <div>
            <i className="fa fa-lock mr-2"></i>
            Téléchargements
          </div>
          <div></div>
        </header>
        <main>
          <DownloadForm />
        </main>
      </div>
    );
  }
}

export default Download;
