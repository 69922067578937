import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, confirm, userInfosFilled } from './actions';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import AuthForm from './AuthForm';
import Dropdown from '../../components/Dropdown/Dropdown';
import DropdownItem from '../../components/Dropdown/DropdownItem';
import { Route, Redirect, Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import RippleEffect from '../../lib/Ripple/RippleEffect';
import Slide from '@material-ui/core/Slide';
import { confs } from '../../conf';
import logoPNG from '../../public/img/letter-s.png';
import { isMobile } from '../../utils';
import qs from 'qs';

const { PRIMARY_BG } = confs;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    padding: '0 1rem',
    backgroundImage: 'url(\'https://client.somesca.fr/api/somesca/images/image_1.jpg\')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  button: {
    background: 'rgba(0, 0, 0, .3)',
    border: 'none',
    color: 'white',
    padding: '.5rem',
    borderRadius: '5px'
  },
  form: {
    padding: '1rem',
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 0 5px 0 rgba(0,0,0,.2)',
    flexBasis: isMobile() ? '100%' : '50%',
  }
}

const OtherLink = () => (
  <a href="#" style={{position: 'relative'}}>Autre</a>
)

class AuthRoute extends Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (props.auth.user && props.auth.user.token && props.auth.confirmed === false) {
      return  {
        token: props.auth.user.token
      }
    }
    if ((state.blanked && props.auth.fetching === false)) {
      return {
        identifiant: '',
        password: '',
        blanked: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const { token } = this.state;
    if (token) {
      this.props.confirm(token);
    }
  }

  login() {
    this.props.login(this.state);
    this.setState({ blanked: true });
  }

  blank() {
    this.setState({});
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  showOptions() {
    this.setState({
      showOptions: true,
    });
  }

  render () {
    const { confirmed, confirmFetching, fetching, user } = this.props.auth;
    const params = qs.parse(this.props.location.search.replace('?', ''));
    const { identifiant, password } = this.state;

    if ((!confirmFetching && confirmed)) {
      document.body.classList.remove('not-connected');
      document.body.classList.add('connected');
    } else {
      document.body.classList.remove('connected');
      document.body.classList.add('not-connected');

    }

    return (
      <>
        {
            confirmFetching &&
              <LoadingComponent />
        }
       {
          (!confirmFetching && confirmed) &&
            <Route exact={this.props.exact} path={this.props.path} component={this.props.component} />
       }
       {
         ((confirmed && !userInfosFilled(user) && !(user.administrateur === true))) &&
          <Dialog
           fullScreen
           open={true}
           TransitionComponent={Transition}
          >
            <div>
              <AuthForm />
            </div>
          </Dialog>
       }
       {
         ((!user.token || !confirmFetching)) &&
           <Dialog
             fullScreen
             open={!confirmed}
             TransitionComponent={Transition}
           >
            <div style={style.container} id={"auth-login"}>
              <form style={style.form}>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-3" >
                      <img src={logoPNG} className={"img-fluid mb-4"} alt=""/>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                    <div className={"col-md-6 col-lg-6"}>
                      <div className="label--form">
                        Mon identifiant
                        <input
                          type="text"
                          className={"form-control mb-2"}
                          name="identifiant"
                          placeholder={"Identifiant"}
                          value={identifiant}
                          onChange={(e) => this.handleChange(e)}
                          maxLength={25}
                        />
                      </div>
                      <div className="label--form">
                        Mon mot de passe
                        <input
                          type="password"
                          className={"form-control mb-2"}
                          name="password"
                          placeholder={"Mot de passe"}
                          value={password}
                          onChange={(e) => this.handleChange(e)}
                          maxLength={25}
                        />
                     </div>
                       <RippleEffect onClick={() => this.login()}>
                        <button disabled={fetching} type={"button"} style={style.button} className={"btn-block"}>{fetching===true?'Connexion ...':'Me connecter'}</button>
                       </RippleEffect>

                       <Link
                         to={"/forgot-identifier?"}
                         replace
                       >
                         <em>J'ai oublié mon identifiant</em>
                       </Link>
                       <br/>
                        {/* Procédure de reinitialisation de mot de passe */}
                        <Link
                         to={"/reset-password/demand?" + (identifiant?'identifiant='+identifiant:'')}
                         replace
                        >
                         <em>J'ai oublié mon mot de passe</em>
                        </Link>
                       <div>
                         <a href="#" onClick={() => this.showOptions()}>Autres...</a>
                         {
                           this.state.showOptions &&
                            <ul>
                              <li>
                                <Link
                                  to={"/account/validation/revival?" + (identifiant?"identifiant="+identifiant: '')}
                                  replace
                                >
                                  <em>Je n'ai pas recu d'email de validation suite à ma première connexion</em>
                                </Link>
                              </li>
                            </ul>
                         }
                       </div>
                     </div>

                   </div>
                 </div>
               </form>
             </div>
           </Dialog>
       }
     </>

    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login, confirm })(AuthRoute);
