import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { connect } from 'react-redux';
import { hideModal } from './actions';

class Modal extends React.Component {

  onValidation() {
    this.props.modal.onValidation();
    this.props.hideModal();
  }

  onDismiss() {
    this.props.modal.onDismiss();
    this.props.hideModal();
  }

  onClose() {
    if (!this.props.modal.closeOnClickAway) return;

    this.props.hideModal();

  }

  render() {
    const { modal } = this.props;

    const Component = modal.component || null;

    return (
      <Dialog
        open={modal.open}
        onClose={() => this.onClose()}
        TransitionComponent={Fade}
      >
            <DialogTitle id="simple-dialog-title">{modal.title}</DialogTitle>
            <div style={{padding: '1rem'}}>
              <p>
                {modal.content && modal.content}
                {
                  modal.component &&
                    <Component {...modal.componentProps} />
                }
              </p>
              <div className={"d-flex justify-content-end"}>
                {
                  modal.onValidation &&
                    <button
                      onClick={() => this.onValidation()}
                      className={"btn btn-primary"}
                    >
                      {modal.onValidationTitle || 'Ok'}
                    </button>
                }
                {
                  modal.onDismiss &&
                    <button
                      onClick={() => this.onDismiss()}
                      className={"btn btn-danger"}
                    >
                      {modal.onDismissTitle || 'No'}
                    </button>
                }
              </div>
            </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal,
});

export default connect(mapStateToProps, { hideModal })(Modal);
