import UserMail from './UserMail';
import CGU from './CGU';

export const openMailModal = () => ({
  type: 'SHOW_MODAL', payload: {
    component: UserMail,
    title: 'Envoyer un email à Somesca.',
    closeOnClickAway: true,
  }
});

export const sendMail = (params) => ({
  type: 'SEND_MAIL', payload: params
});

export const openCGUModal = () => ({
  type: 'SHOW_MODAL', payload: {
    component: CGU,
    onValidationTitle: 'Fermer',
    onValidation: () => console.log('Close'),
    title: 'Avis sur les données personnelles et Conditions Générales d\'Utilisation (CGU)',
    closeOnClickAway: true,
  }
});
