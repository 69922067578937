import React from 'react';
import { Link } from 'react-router-dom';
import RippleEffect from '../../lib/Ripple/RippleEffect';
import { userInfosFilled, putUser } from '../Auth/actions';
import { connect } from 'react-redux';

const style={
  button: {
    background: 'rgba(0, 0, 0, .3)',
    border: 'none',
    color: 'white',
    padding: '.5rem 1rem',
    borderRadius: '5px',
    fontSize: '1rem',
    textDecoration: 'none',
  },
  form: {
    padding: '1rem',
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 0 5px 0 rgba(0,0,0,.2)',
  },
  title: {
    fontSize: '1.3rem',
  },
}

class UserForm extends React.Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (props.auth.user.token !== state.token) {
      return {
        ...props.auth.user,
      };
    }
    return null;
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      modified: true,
    })
  }

  validateInformations() {
    if (
        !userInfosFilled(this.state) ||
        !(this.state.conditions_accepted === 1)
      ) {
      this.setState({
        error: 'Tous les champs doivent être remplis et les conditions générales d\'utilisations doivent être acceptées',
      });
      return;
    }
    const params = {
      access_token: this.props.auth.user.token,
      idcli: this.props.auth.user.idcli,
      ...this.state,
    }
    this.props.putUser(params);
    this.setState({
      modified: false,
    });
  }

  isFormValid() {
    return (
      userInfosFilled(this.state) &&
      this.state.modified === true
    );
  }

  render() {
    const {
      nom,
      prenom,
      societe,
      ruesociete,
      villesociete,
      cpsociete,
      payssociete,
      telfixe,
      telport,
      mail,
      conditions_accepted,
    } = this.state;

    return (
      <div className="container">
        <h3>
          Vous
        </h3>
         <div className="label--form">
          Nom
          <input type="text" className={"form-control mb-2"} name="nom" placeholder={"Nom"} value={nom} onChange={(e) => this.handleChange(e)}/>
         </div>
         <div className="label--form">
          Prénom
          <input type="text" className={"form-control mb-2"} name="prenom" placeholder={"Prénom"} value={prenom} onChange={(e) => this.handleChange(e)}/>
         </div>
         <div className="label--form">
          Email
          <input type="email" className={"form-control mb-2"} name="mail" placeholder={"Email"} value={mail} onChange={(e) => this.handleChange(e)}/>
         </div>
         <h3>
           La société
         </h3>
         <div className="label--form">
          Société
          <input type="text" className={"form-control mb-2"} name="societe" placeholder={"Nom"} value={societe} onChange={(e) => this.handleChange(e)}/>
         </div>
         <div className="label--form">
          Rue
          <input type="text" className={"form-control mb-2"} name="ruesociete" placeholder={"Rue"} value={ruesociete} onChange={(e) => this.handleChange(e)}/>
         </div>
         <div className="label--form">
          Ville
          <input type="text" className={"form-control mb-2"} name="villesociete" placeholder={"Ville"} value={villesociete} onChange={(e) => this.handleChange(e)}/>
         </div>
         <div className="label--form">
          Code postal
          <input type="text" className={"form-control mb-2"} name="cpsociete" placeholder={"Code postal"} value={cpsociete} onChange={(e) => this.handleChange(e)}/>
         </div>
         <div className="label--form">
          Pays
          <input type="text" className={"form-control mb-2"} name="payssociete" placeholder={"Pays"} value={payssociete} onChange={(e) => this.handleChange(e)}/>
         </div>
         <div className="label--form">
          Téléphone fixe
          <input type="text" className={"form-control mb-2"} name="telfixe" placeholder={"Téléphone fixe"} value={telfixe} onChange={(e) => this.handleChange(e)}/>
         </div>
         <div className="label--form">
          Téléphone portable
          <input type="text" className={"form-control mb-2"} name="telport" placeholder={"Téléphone portable"} value={telport} onChange={(e) => this.handleChange(e)}/>
         </div>
         <RippleEffect onClick={() => this.validateInformations(this.state)}>
          <button type={"button"} disabled={this.props.auth.validatingInformations || !this.isFormValid()} style={style.button} className={"btn-block mb-2"}>{this.props.auth.validatingInformations?"Validation des données...":"Valider mes informations"}</button>
         </RippleEffect>
         <hr/>
         <RippleEffect>
           <Link to={"/reset-password"} style={style.button} className={"btn-block mt-2 bg-danger"}>Réinitialiser mon mot de passe</Link>
         </RippleEffect>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, { putUser })(UserForm);
