import {
  SHOW_MODAL,
  HIDE_MODAL,
} from './actions';

const initialState = {
  open: false,
};

export default function (state= initialState, action) {
  switch (action.type) {
    case SHOW_MODAL: {
      if (action.payload.before) {
        action.payload.before();
      }
        return {
          title: action.payload.title,
          content: action.payload.content,
          type: action.payload.type,
          onValidation: action.payload.onValidation,
          onValidationTitle: action.payload.onValidationTitle,
          onDismissTitle: action.payload.onDismissTitle,
          component: action.payload.component,
          componentProps: action.payload.componentProps,
          closeOnClickAway: action.payload.closeOnClickAway,
          before: action.payload.before,
          open: true,
        };
      }
      break;
    case HIDE_MODAL:
      return { open: false };
      break;
    default:
      return { open: false };
      break;
  }
}
