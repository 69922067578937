import React from 'react';
import RippleEffect from '../../lib/Ripple/RippleEffect';
import { confs } from '../../conf';
import { userInfosFilled, postUser, logout } from './actions';
import { openCGUModal } from '../User/actions';
import { connect } from 'react-redux';

const { URL_API } = confs;

const style={
  button: {
    background: 'rgba(0, 0, 0, .3)',
    border: 'none',
    color: 'white',
    padding: '.5rem',
    borderRadius: '5px'
  },
  form: {
    padding: '1rem',
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 0 5px 0 rgba(0,0,0,.2)',
    flexBasis: '50%',
  },
  small: {
    fontSize: '.8rem',
  },
}

class AuthForm extends React.Component {
  state = {
    password: '',
    payssociete: 'FRANCE',
  };

  componentDidMount() {
    if (this.conf) {
      this.conf.addEventListener('paste', (e) => this.onPaste(e));
    }
  }

  onPaste(e) {
    e.preventDefault();
    alert('Vous ne pouvez pas copier dans ce champ!');
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleCheckboxChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.checked ? 1 : 0,
    }, () => console.log(this.state));
  }

  validateInformations() {
    if (
        !userInfosFilled(this.state) ||
        !(this.state.conditions_accepted === 1)
      ) {
      this.setState({
        error: 'Tous les champs doivent être remplis et les conditions générales d\'utilisations doivent être acceptées',
      });
      return;
    }
    const params = {
      access_token: this.props.auth.user.token,
      idcli: this.props.auth.user.idcli,
      ...this.state,
    }
    this.props.postUser(params);
  }

  isFormValid() {
    return (
      userInfosFilled(this.state) &&
      this.isPasswordValid() &&
      this.state.mail === this.state.confirm_mail &&
      (this.state.conditions_accepted === 1)
    );
  }

  isPasswordValid() {
    return (
      this.state.password === this.state.confirm &&
      this.state.password.length >= 7
    );
  }

  render() {
    const {
      nom,
      prenom,
      societe,
      ruesociete,
      villesociete,
      cpsociete,
      payssociete,
      telfixe,
      telport,
      mail,
      conditions_accepted,
      password,
      confirm,
      confirm_mail,
    } = this.state;
    return (
      <div className="row align-items-center justify-content-center p-2">
        <div className="col-lg-4 col-md-6">
          <h1 style={{fontSize: '1rem'}} className={"alert alert-warning"}>
            Afin de poursuivre, veuillez remplir le formulaire ci-dessous.
            <div>
              <strong>Tous les champs sont obligatoires</strong> (*)
            </div>
          </h1>
          <form style={style.form}>
            <div className="container">
              <h3>
                Vous
              </h3>
                <div className="label--form">
                  Nom*
                  <input type="text" maxLength={130} className={"form-control mb-2"} name="nom" placeholder={"Nom"} value={nom} onChange={(e) => this.handleChange(e)}/>
                </div>
                <div className="label--form">
                  Prénom*
                  <input type="text" maxLength={130} className={"form-control mb-2"} name="prenom" placeholder={"Prénom"} value={prenom} onChange={(e) => this.handleChange(e)}/>
               </div>
               <div className="label--form">
                Email*
                <input type="text" maxLength={130} className={"form-control mb-2"+((mail===confirm_mail)?'':' invalid ')} autocomplete={"false"} name="mail" placeholder={"Email"} value={mail} onChange={(e) => this.handleChange(e)}/>
               </div>
               <div className="label--form">
                Confirmer*
                <input ref={conf => this.conf = conf} type="text" maxLength={130} className={"form-control mb-2"} name="confirm_mail" autocomplete={"false"} placeholder={"Confirmer"} value={confirm_mail} onChange={(e) => this.handleChange(e)}/>
               </div>
               <hr/>
               <h3>
               La société
               </h3>
               <div className="label--form">
                Société*
                <input type="text" maxLength={130} className={"form-control mb-2"} name="societe" placeholder={"Nom"} value={societe} onChange={(e) => this.handleChange(e)}/>
               </div>
               <div className="label--form">
                Rue*
                <input type="text" maxLength={130} className={"form-control mb-2"} name="ruesociete" placeholder={"Rue"} value={ruesociete} onChange={(e) => this.handleChange(e)}/>
               </div>
               <div className="label--form">
                Ville*
                <input type="text" maxLength={130} className={"form-control mb-2"} name="villesociete" placeholder={"Ville"} value={villesociete} onChange={(e) => this.handleChange(e)}/>
               </div>
               <div className="label--form">
                Code postal*
                <input type="text" maxLength={50} className={"form-control mb-2"} name="cpsociete" placeholder={"Code postal"} value={cpsociete} onChange={(e) => this.handleChange(e)}/>
               </div>
               <div className="label--form">
                Pays*
                <input type="text" maxLength={130} className={"form-control mb-2"} name="payssociete" placeholder={"Pays"} value={payssociete} onChange={(e) => this.handleChange(e)}/>
               </div>
               <div className="label--form">
                Téléphone fixe*
                <input type="text" maxLength={50} className={"form-control mb-2"} name="telfixe" placeholder={"Téléphone fixe"} value={telfixe} onChange={(e) => this.handleChange(e)}/>
               </div>
               <div className="label--form">
                Téléphone portable*
                <input type="text" maxLength={50} className={"form-control mb-2"} name="telport" placeholder={"Téléphone portable"} value={telport} onChange={(e) => this.handleChange(e)}/>
               </div>

               <h3>
                 Votre mot de passe
                 <div>
                  <small style={style.small}><em>Définissez un mot de passe dont il vous faudra se souvenir pour vos connexions futures.</em></small>
                 </div>
               </h3>
               <div className="alert alert-primary">
                 Le mot de passe doit faire au minimum 7 caractères.
               </div>
                <div className="label--form">
                  Mot de passe*
                  <input type="password" maxLength={25} className={"form-control mb-2"} name="password" placeholder={"Votre nouveau mot de passe"} value={password} onChange={(e) => this.handleChange(e)}/>
                </div>
                <div className="label--form">
                  Confirmer*
                  <input type="password" maxLength={25} className={"form-control mb-2"} name="confirm" placeholder={"Confirmer le mot de passe"} value={confirm} onChange={(e) => this.handleChange(e)}/>
                </div>
                {
                  (this.state.password.length > 0 && !this.isPasswordValid()) &&
                    <div className="alert alert-danger">
                      Les mots de passe ne correspondent pas ou la longueur est inférieure à 7 caractères.
                    </div>
                }
               <hr/>
               <label>
                <input type={"checkbox"} name={"conditions_accepted"} value={conditions_accepted} onChange={(e) => this.handleCheckboxChange(e)}/>
                  J'accepte les <a target={"_blank"} href={URL_API + "/somesca/images/cgu.pdf"} download>conditions d'utilisations</a>
               </label>
               {
                 !this.isFormValid() &&
                   <div className="alert alert-danger mt-2">
                    Tous les champs sont obligatoires.
                   </div>
               }
               <RippleEffect onClick={() => this.validateInformations(this.state)}>
                <button type={"button"} disabled={this.props.auth.validatingInformations || !this.isFormValid()} style={style.button} className={"btn-block "+(this.isFormValid()?'bg-success':'bg-danger')}>{this.props.auth.validatingInformations?"Validation des données...":"Valider mes informations"}</button>
               </RippleEffect>
               <a href="#" onClick={() => this.props.logout({access_token: this.props.auth.user.token})}>
                Plus tard
               </a>

             </div>
           </form>
         </div>
       </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, { postUser, openCGUModal, logout })(AuthForm);
