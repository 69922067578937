export const REGISTER = 'REGISTER';
export const register = (user) => ({
  type: REGISTER, payload: user
});
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const LOGIN = 'LOGIN';
export const login = (credentials) => ({
  type: LOGIN, payload: credentials
});
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const logout = (access_token) => ({
  type: LOGOUT, payload: access_token
});
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const CONFIRM = 'CONFIRM';
export const confirm = (access_token) => ({
  type: CONFIRM, payload: access_token
});
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
export const CONFIRM_FAILED = 'CONFIRM_FAILED';

//
export const userInfosFilled = user => {
  return (
    user.nom &&
    user.prenom &&
    user.societe &&
    user.ruesociete &&
    user.villesociete &&
    user.cpsociete &&
    user.payssociete &&
    user.telfixe &&
    user.telport &&
    user.mail
  );
}

export const POST_USER = 'POST_USER';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILED = 'POST_USER_FAILED';
export const postUser = (params) => ({
  type: POST_USER, payload: params,
});

export const PUT_USER = 'PUT_USER';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILED = 'PUT_USER_FAILED';
export const putUser = (params) => ({
  type: PUT_USER, payload: params,
});

export const VALIDATION_ACCOUNT = 'VALIDATION_ACCOUNT';
export const VALIDATION_ACCOUNT_SUCCESS = 'VALIDATION_ACCOUNT_SUCCESS';
export const VALIDATION_ACCOUNT_FAILED = 'VALIDATION_ACCOUNT_FAILED';
export const validationAccount = (params) => ({
  type: VALIDATION_ACCOUNT, payload: params,
});

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const resetPassword = (params) => ({
  type: RESET_PASSWORD, payload: params,
});

export const RESET_PASSWORD_DEMAND = 'RESET_PASSWORD_DEMAND';
export const RESET_PASSWORD_DEMAND_SUCCESS = 'RESET_PASSWORD_DEMAND_SUCCESS';
export const RESET_PASSWORD_DEMAND_FAILED = 'RESET_PASSWORD_DEMAND_FAILED';
export const resetPasswordDemand = (params) => ({
  type: RESET_PASSWORD_DEMAND, payload: params,
});

export const FORGOT_IDENTIFIER = 'FORGOT_IDENTIFIER';
export const FORGOT_IDENTIFIER_SUCCESS = 'FORGOT_IDENTIFIER_SUCCESS';
export const FORGOT_IDENTIFIER_FAILED = 'FORGOT_IDENTIFIER_FAILED';
export const forgotIdentifier = (params) => ({
  type: FORGOT_IDENTIFIER, payload: params,
});

export const SEND_REVIVAL = 'SEND_REVIVAL';
export const SEND_REVIVAL_SUCCESS = 'SEND_REVIVAL_SUCCESS';
export const SEND_REVIVAL_FAILED = 'SEND_REVIVAL_FAILED';
export const sendRevival = (params) => ({
  type: SEND_REVIVAL, payload: params,
});
