import {
  // register actions
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  // login actions
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  // logout actions
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  // confirm actions
  CONFIRM,
  CONFIRM_SUCCESS,
  CONFIRM_FAILED,
  //
  POST_USER,
  POST_USER_SUCCESS,
  POST_USER_FAILED,
  //
  PUT_USER,
  PUT_USER_SUCCESS,
  PUT_USER_FAILED,
  //
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  //
  RESET_PASSWORD_DEMAND,
  RESET_PASSWORD_DEMAND_SUCCESS,
  RESET_PASSWORD_DEMAND_FAILED,
  //
  FORGOT_IDENTIFIER,
  FORGOT_IDENTIFIER_SUCCESS,
  FORGOT_IDENTIFIER_FAILED,
  //
  SEND_REVIVAL,
  SEND_REVIVAL_SUCCESS,
  SEND_REVIVAL_FAILED,

  VALIDATION_ACCOUNT_SUCCESS,
  VALIDATION_ACCOUNT_FAILED
} from './actions';

const initialeState = {
  user: {},
  fetching: false,
  confirmFetching: false,
  confirmed: false,
  logoutLoading: false,
  validatingInformations: false,
  resetingPassword: false,
  resetingPasswordDemand: false,
  fetching_forgotIdentifier: false,
  sendingRevival: false,
  verified: null,
};

export default function (state=initialeState, action) {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        fetching: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: action.payload.data,
      };
    case REGISTER_FAILED:
      return {
        ...state,
        fetching: false,
        user: {},
      };
    // Login actions
    case LOGIN:
      return {
        ...state,
        fetching: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        confirmed: true,
        user: action.payload.data,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        fetching: false,
        confirmed: false,
        user: {},
      };
    case LOGOUT:
      return {...state, logoutLoading: true};
      break;
    case LOGOUT_SUCCESS: {
        return {
          ...state,
          confirmed: false,
          logoutLoading: false,
          user: {},
        };
        break;
    }
    case LOGOUT_FAILED:
      return {
        ...state,
        logoutLoading: false,
      };
      break;
    case CONFIRM:
      return {
        ...state,
        confirmed: false,
        confirmFetching: true,
      };
      break;
    case CONFIRM_SUCCESS:
      return {
        ...state,
        confirmed: true,
        confirmFetching: false,
        user: {...state.user, ...action.payload.data},
      };
      break;
    case CONFIRM_FAILED:
      return {
        ...state,
        confirmFetching: false,
        confirmed: false,
        user: {},
      };
      break;
    case POST_USER:
      return {
        ...state,
        validatingInformations: true,
      };
      break;
    case POST_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        validatingInformations: false,
      };
      break;
    case POST_USER_FAILED:
      return {
        ...state,
        validatingInformations: false,
      };
      break;
    case PUT_USER:
      return {
        ...state,
        validatingInformations: true,
      };
      break;
    case PUT_USER_SUCCESS:
      return {
        ...state,
        user: {...state.user, ...action.payload},
        validatingInformations: false,
      };
      break;
    case PUT_USER_FAILED:
      return {
        ...state,
        validatingInformations: false,
      };
      break;
    case RESET_PASSWORD:
      return {
        ...state,
        resetingPassword: true,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetingPassword: false,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetingPassword: false,
      };
      break;
    case RESET_PASSWORD_DEMAND:
      return {
        ...state,
        resetingPasswordDemand: true,
      };
      break;
    case RESET_PASSWORD_DEMAND_SUCCESS:
      return {
        ...state,
        resetingPasswordDemand: false,
      };
    case RESET_PASSWORD_DEMAND_FAILED:
      return {
        ...state,
        resetingPasswordDemand: false,
      };
      break;
    case FORGOT_IDENTIFIER:
      return {
        ...state,
        fetching_forgotIdentifier: true,
      };
      break;
    case FORGOT_IDENTIFIER_SUCCESS:
      return {
        ...state,
        fetching_forgotIdentifier: false,
      };
    case FORGOT_IDENTIFIER_FAILED:
      return {
        ...state,
        fetching_forgotIdentifier: false,
      };
    case SEND_REVIVAL:
      return {
        ...state,
        sendingRevival: true,
      };
      break;
    case SEND_REVIVAL_SUCCESS:
      return {
        ...state,
        sendingRevival: false,
      };
    case SEND_REVIVAL_FAILED:
      return {
        ...state,
        sendingRevival: false,
      };
      break;
    case VALIDATION_ACCOUNT_SUCCESS:
      return {
        ...state,
        verified: true,
      };
      break;
    case VALIDATION_ACCOUNT_FAILED:
      return {
        ...state,
        verified: false,
      };
      break;
    default:
      return state;
  }
}
