import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import { user as api } from './api';

export function* sendMail (action) {
  try {
    let msg = yield call(api.sendMail, action.payload);
    console.log(msg);
    yield put({type: 'SEND_MAIL_SUCCESS', payload: msg});
    yield put({type: 'SHOW_MODAL', payload: {
        type: 'success',
        title: 'Votre message a bien été envoyé!',
        onValidationTitle: 'Merci',
        onValidation: () => console.log('Merci'),
    }});
  } catch (e) {
    if (e.response.data.status && e.response.data.status.code === 401) {
      yield put({type: 'SHOW_MODAL', payload: {
        type: 'Error',
        title: 'Action interrompue: votre session s\'est terminée.',
        onValidationTitle: 'Se reconnecter',
        onValidation: () => window.location.reload(),
      }});
    } else {
      yield put({type: 'SEND_MAIL_FAILED', payload: e.response.data});
      yield put({type: 'SHOW_MODAL', payload: {
        type: 'error',
        title: e.response.data,
        onValidationTitle: 'J\'ai compris',
        onValidation: () => console.log('Merci'),
      }});
    }
  }
}

function* userSaga() {
  yield takeLatest('SEND_MAIL', sendMail);
}

export default userSaga;
