let TEST_MODE;
let HOST;
let confs;

const SOC = "test";

TEST_MODE = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

function getHost() {
  if (TEST_MODE) {
    switch (SOC) {
      case 'test':
        HOST = 'test.ilsduc.fr';
        break;
      case 'prod':
        HOST = 'doselec.ilsduc.fr';
        break;

    }
  } else {
    HOST = window.location.hostname;
  }
  return HOST;
}
/*
  ** Configurations
*/
function getConfigurations (host) {
  switch (host) {
    case "test.ilsduc.fr":
      confs = {
        URL_API: "http://localhost:4000",
        URL_GED: "https://ged.ping.ilsduc.fr",
        FAVICON: '/letter-s.png',
        TITLE: 'Somesca | Rapports d\'intervention',
        META_NAME_CONTENT: 'rgba(255,255,255)',
        MANIFEST: '/manifest.json',
        PRIMARY_BG: '#0076ff',
        SITE_MARCHAND: 'https://www.doselec.com/fr/'
      };
      break;
    case "somesca.ilsduc.fr":
      confs = {
        URL_API: "https://api.somesca.ilsduc.fr",
        FAVICON: '/letter-s.png',
        TITLE: 'Somesca | Rapports d\'intervention',
        META_NAME_CONTENT: 'rgba(255,255,255)',
        MANIFEST: '/manifest.json',
        PRIMARY_BG: '#0076ff',
        SITE_MARCHAND: 'https://www.somesca.fr/'
      };
      break;
    case "client.somesca.fr":
      confs = {
        URL_API: "https://client.somesca.fr/api",
        FAVICON: '/letter-s.png',
        TITLE: 'Somesca | Rapports d\'intervention',
        META_NAME_CONTENT: 'rgba(255,255,255)',
        MANIFEST: '/manifest.json',
        PRIMARY_BG: '#0076ff',
        SITE_MARCHAND: 'https://www.somesca.fr/'
      };
      break;
  }
}

/*
  ** Setting attributes for index.html
*/
export function applyConfiguration() {
  /*
    ** Favicon
  */
  document.getElementById('FAVICON').setAttribute('href', confs.FAVICON);
  /*
    ** Manifest
  */
  document.getElementById('MANIFEST').setAttribute('href', confs.MANIFEST);
  /*
    ** Manifest
  */
  document.getElementById('TITLE').innerHTML = confs.TITLE;
  /*
    ** Meta Themecolor
  */
  document.getElementById('META_NAME_CONTENT').setAttribute('content', confs.META_NAME_CONTENT);

}

getConfigurations(getHost());

applyConfiguration();

export { confs };
