import React from 'react';

class ZipValidation extends React.Component {
  render() {
    return (
      <div>
        <a download className={"btn btn-success"} href={this.props.file}>Télécharger {this.props.name}</a>
      </div>
    );
  }
}

export default ZipValidation;
