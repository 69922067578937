import React from 'react';
import { connect } from 'react-redux';
import { goToPath } from './actions';

const Folder = ({ name, onClick, open, active, goToPath }) => (
  <div
    className={"ilsduc-tree-folder"+(open?' open ':'')+(active?' active ':'')}
    onClick={() => onClick()}
  >
    {
      open &&
        <div className={"icon-tree-folder"}>
          <i className="fa fa-folder-open"></i>
        </div>
    }
    {
      !open &&
        <div className={"icon-tree-folder"}>
          <i className="fa fa-folder"></i>
        </div>
    }
    <span className={"ellipsis"}>{name}</span>
    <div className={"ml-2 go-to-icon"} onClick={() => onClick()}>
      <i className="fa fa-share" onClick={() => goToPath()}></i>
    </div>
  </div>
);

const File = ({ name, onClick, active }) => (
  <div
    className={"ilsduc-tree-folder --file "+(active?' active ':'')}
    onClick={() => onClick()}
  >
    <div className={"icon-tree-folder"}>
      <i className="fa fa-file-text"></i>
    </div>
    <span className={"ellipsis"}>{name}</span>
  </div>
);

class ExplorerMenu extends React.Component {

  state = {
    opened: [],
    selectedFile: '',
  };

  componentDidMount() {
    if (this.resizeBar) {
      this.resizeBar.addEventListener('mousedown', (e) => {
        e.preventDefault()
        this.resizeBar.addEventListener('mousemove', (e) => this.handleResize(e))
        this.resizeBar.addEventListener('mouseup', (e) => this.stopResize(e))
      });
    }
  }

  handleResize (e) {
    this.explorerZone.style.width = e.pageX - this.explorerZone.getBoundingClientRect().left + 'px'
  }

  stopResize (e) {
    this.resizeBar.removeEventListener('mousemove', (e) => this.handleResize(e));
  }

  getMenu(explorer) {

    const { currentFolder } = this.props;

    if (this.state.opened.indexOf(explorer.path) < 0) {
      // folder is open
      return (
        <div className={"tree-list"+(explorer.path === currentFolder.path?' active ':'')}>
          {
            (explorer.type == "folder") &&
              <Folder
                active={currentFolder.path === explorer.path}
                name={explorer.name}
                onClick={() => this.toggle(explorer.path)}
                goToPath={() => this.goToPath(explorer.path)}
                open={true}
              />
          }
          {
            !(explorer.type == "folder") &&
              <File
                onClick={() => this.selectFile(explorer.path)}
                active={this.state.selectedFile === explorer.path}
                name={explorer.name}
              />
          }
          {
            explorer.type == 'folder' && explorer.content.map(folder => (
              <div className={"ilsduc-tree-submenu"} >
                {this.getMenu(folder)}
              </div>
            ))
          }
        </div>
      );
    } else {
      if(currentFolder.path === explorer.path) {
        this.toggle(currentFolder.path);
      }
      return (
        <>
          {
            (explorer.type == "folder") &&
              <Folder
                active={currentFolder.path === explorer.path}
                name={explorer.name}
                goToPath={() => this.goToPath(explorer.path)}
                onClick={() => this.toggle(explorer.path)}
                open={false}
              />
          }
          {
            !(explorer.type == "folder") &&
              <File
                onClick={() => this.selectFile(explorer.path)}
                active={this.state.selectedFile === explorer.path}
                name={explorer.name}
              />
          }
        </>
      );
    }
  }

  goToPath(path) {
    //
    if (this.state.opened.indexOf(path) > -1) {
      // have to open it
      this.toggle(path);
    }
    //
    this.props.goToPath(path);
  }

  toggle(path) {
    let opened = this.state.opened;
    let index = opened.indexOf(path);
    if (index > -1) {
      opened.splice(index, 1);
    } else {
      opened.push(path);
    }
    //
    this.setState({...this.state,  opened });
  }

  selectFile (path) {
    let pathPart = path.split('/');
    pathPart.splice(pathPart.length - 1, 1);
    let newPath = pathPart.join('/');

    this.props.goToPath(newPath);

    this.setState({...this.state, selectedFile: path})

  }

  render() {
    const { explorer } = this.props;
    return (
      <div className={"ilsduc-explorer-tree"}>
        <div ref={explorerZone => this.explorerZone = explorerZone} className={"explorer-zone"}>
          {this.getMenu(explorer)}
        </div>
        <div ref={resize => this.resizeBar = resize} className={"resizable-bar"}></div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  explorer: state.explorer.explorer,
  currentFolder: state.explorer.currentFolder,
});

export default connect(mapStateToProps, { goToPath })(ExplorerMenu);
