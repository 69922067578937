import React, { Component } from 'react';
import { hexToRgbA } from '../../utils';
import { confs } from '../../conf';
import './style.css';

const { PRIMARY_BG } = confs;

const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    backgroundColor: 'rgba(0,0,0,.4)',
    zIndex: '1000000000',
  }
}

class SnapchatLoader extends Component {
  render() {
    const { color, visible } = this.props;

    if (!visible) return null;

    return (
      <div style={style.container}>
        <div className={"flexbox justify-content-center align-items-center"}>
          <div className={"btn-loader"}>
            <div className={"large-circle"} style={{borderColor: color}}></div>
            <div className={"small-circle"} style={{borderColor: color}}></div>
          </div>
        </div>
      </div>
    );
  }

}

export default SnapchatLoader;
