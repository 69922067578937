import { all } from 'redux-saga/effects';
import authSaga from '../components/Auth/saga';
import explorerSaga from '../components/Explorer/saga';
import userSaga from '../components/User/saga';
import downloadSaga from '../components/Download/saga';


export function* rootSagas() {
  yield all ([
    authSaga(),
    explorerSaga(),
    userSaga(),
    downloadSaga(),
  ]);
}
