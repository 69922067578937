import React from 'react';
import { connect } from 'react-redux';

export const sendMail = (params) => ({
  type: 'SEND_MAIL', payload: params
});

class UserMail extends React.Component {
  state = {};

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      error: null,
    });
  }

  send() {
    if (!(this.state.subject) || !(this.state.body)) {
      this.setState({
        error: 'Tous les champs sont obligatoires',
      });
      return;
    }
    const params = {
      access_token: this.props.auth.user.token,
      ...this.state,
    };
    this.props.sendMail(params);
  }

  render() {
    return (
      <div className={"row p-3"}>
        {
          this.state.error &&
            <div className="alert alert-danger mb-2 btn-block">
              {this.state.error}
            </div>
        }
        <input
          type="text"
          name="subject"
          placeholder={"Sujet"}
          className="form-control mb-2"
          value={this.state.subject}
          onChange={(e) => this.handleChange(e)}
        />
        <textarea
          name="body"
          id=""
          cols="30"
          rows="10"
          placeholder={"Votre message"}
          className="form-control"
          onChange={(e) => this.handleChange(e)}
        >
          {this.state.body}
        </textarea>
        <button
          className="btn btn-success btn-block mt-2"
          onClick={() => this.send()}
        >
          Envoyer à Somesca
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { sendMail })(UserMail);
