import React from 'react';
import Button from '../Button/Button';
import { toggleSelectionMode, selectAllFolder, sendSelectedFolders, unselectAll } from '../Explorer/actions';
import { connect } from 'react-redux';
import './style.css';

class ToolZIP extends React.Component {

  state = {
    selectedFolders: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.selectedFolders.length !== state.selectedFolders.length) {
      return {
        selectedFolders: props.selectedFolders,
      };
    }
    return null;
  }

  async handleSendSelection() {
    // create parameters object
    const params = {
      access_token: this.props.auth.user.token,
      selectedFolders: this.props.selectedFolders,
      structure: this.props.explorer,
    };
    // send request
    await this.props.sendSelectedFolders(params);

    this.props.toggleSelectionMode();
  }

  render() {
    return (
      <div className={"toolzip " + (this.props.selectionMode ? 'show' : '') }>
        <div className={"d-flex align-items-center"}>
          <div className={"d-flex"}>
            <Button onClick={() => this.props.selectAllFolder()}>
              <i className="fa fa-hand-pointer-o mr-2"></i> Tout séléctionner
            </Button>
            {
              this.state.selectedFolders.length > 0 &&
              <Button onClick={() => this.props.unselectAll()}>
                <i className="fa fa-minus-square-o mr-2"></i> Tout déséléctionner
              </Button>
            }
          </div>
          <div className="hr-v"></div>
          <div className={"d-flex"}>
            <Button disabled={(this.state.selectedFolders.length == 0 || this.props.zipping)} onClick={() => this.handleSendSelection()}>
              <i className="fa fa-upload mr-2"></i> {this.props.zipping?'Création en cours ...': 'Créer l\'archive'}
            </Button>
            <Button onClick={() => this.props.toggleSelectionMode()}>
              <i className="fa fa-times mr-2"></i> Sortir du mode archive
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectionMode: state.explorer.selectionMode,
  selectedFolders: state.explorer.selectedFolders,
  explorer: state.explorer.explorer,
  zipping: state.explorer.zipping,
  auth: state.auth,
});

export default connect(mapStateToProps, { toggleSelectionMode, selectAllFolder, sendSelectedFolders, unselectAll })(ToolZIP);
