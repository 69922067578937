import { call, put, takeLatest } from 'redux-saga/effects';
import { confs } from '../../conf';
import { explorer } from './api';
import { getFoldersToZip } from '../../utils';
import history from '../../history';
import ZipValidation from './ZipValidation';

const { URL_API } = confs;

export function* getAccess(action) {
  try {
    yield put ({type: 'BLANK_EXPLORER'});
    const explore = yield call(explorer.getAccess, action.payload);
    yield put ({type: 'GET_ACCESS_SUCCESS', payload: explore});
  } catch (e) {
    if (e.response.data.status && e.response.data.status.code === 401) {
      yield put({type: 'SHOW_MODAL', payload: {
        type: 'Error',
        title: 'Action interrompue: votre session s\'est terminée.',
        onValidationTitle: 'Se reconnecter',
        onValidation: () => window.location.reload(),
      }});
    } else {
      yield put ({type: 'GET_ACCESS_FAILED'});
      yield put ({type: 'SHOW_MODAL', payload: {
        type: 'error',
        title: 'Une erreur s\'est produite!',
        // content: e.response.data.status.msg,
        content: e.response.data.data.libelle,
      }});
    }
  }
}

export function* sendSelectedFolders(action) {
  try {
    const access_token = action.payload.access_token;
    const selectedFolders = action.payload.selectedFolders;
    const structure = action.payload.structure;

    const foldersToZip = getFoldersToZip(selectedFolders, structure);

    const params = {
      access_token,
      selectedFolders: foldersToZip,
    };

    const explore = yield call(explorer.sendSelectedFolders, params);
    yield put ({type: 'SEND_SELECTED_FOLDERS_SUCCESS'});
    yield put({type: 'SHOW_MODAL', payload: {
        type: 'success',
        title: 'Votre archive est prête!',
        onValidationTitle: 'Fermer',
        onValidation: () => console.log('Close'),
        component: ZipValidation,
        componentProps: explore,
    }});
  } catch (e) {
    yield put ({type: 'SEND_SELECTED_FOLDERS_FAILED'});
    if (e.response.data.status && e.response.data.status.code === 401) {
      yield put({type: 'SHOW_MODAL', payload: {
        type: 'Error',
        title: 'Action interrompue: votre session s\'est terminée.',
        onValidationTitle: 'Se reconnecter',
        onValidation: () => window.location.reload(),
      }});
    } else {
      yield put({type: 'SHOW_MODAL', payload: {
        type: 'Error',
        title: e.response.data,
        onValidationTitle: 'Fermer',
        onValidation: () => console.log('Close'),
      }});
    }
  }
}

function* explorerSaga() {
  yield takeLatest('GET_ACCESS', getAccess);
  yield takeLatest('SEND_SELECTED_FOLDERS', sendSelectedFolders);
}

export default explorerSaga;
