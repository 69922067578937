import axios from 'axios';
import qs from 'qs';
import { confs } from '../../conf';

const { URL_API } = confs;

export const explorer = {
  getAccess : (params) => axios.get(URL_API+'/users/access', {params}, {withCredentials: true})
    .then(res => res.data.data),
  sendSelectedFolders: (params) => axios.post(URL_API+'/archive/zip', qs.stringify(params))
    .then(res => res.data.data),
};
