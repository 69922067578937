import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { validationAccount } from '../components/Auth/actions';
import { confs } from '../conf';
import history from '../history'
import qs from 'qs';

const { PRIMARY_BG } = confs;

const style = {
  header: {
    background: PRIMARY_BG,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    width: '100%',
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.2)',
    color: 'white',
    fontSize: '1.5rem',
    padding: '1rem',
  },
  button: {
    background: 'rgba(0, 0, 0, .3)',
    border: 'none',
    color: 'white',
    padding: '.5rem',
    borderRadius: '5px'
  },
}

class AccountValidation extends React.Component {

  componentDidMount() {
    const queryparams = qs.parse(this.props.location.search.replace('?', ''))
    const params = {
      token: queryparams.token,
    };

    this.props.validationAccount(params);

  }

  render() {
    return (
      <div>
        <header style={style.header}>
          <div style={{fontSize: '1rem'}}>
            <div className="icon-button" onClick={() => history.replace('/')}>
              <i className="fa fa-arrow-left"></i>
            </div>
          </div>
          <div>
            {
              !this.props.verified &&
                "Verification du compte ..."
            }
          </div>
          <div></div>
        </header>
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">

               {
                 (this.props.verified === null) &&
                    "Validation en cours..."
               }
               {
                 (this.props.verified === true) &&
                    "Le compte est validé"
               }
               {
                 (this.props.verified === false) &&
                    "Erreur dans la validation. Le compte est peut être valide."
               }


               {
                 (this.props.verified !== null) &&
                   <p>
                      <Link to={"/"}>
                        Me connecter à mon compte
                      </Link>
                   </p>
               }
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  verified: state.auth.verified
})

export default connect(mapStateToProps, { validationAccount })(AccountValidation);
