import React from 'react';
import './style.css';
import { goToPath, selectFolder } from './actions';
import { connect } from 'react-redux';
import { isMobile } from '../../utils';

const style = {
  folder: {
    width: '100px',
    height: '100%',
    padding: '.2rem .5rem',
    borderRadius: '3px',
  },
  icon: {
    fontSize: '2.7rem',
    textAlign: 'center'
  },
  name: {
    textAlign: 'center',
    fontSize: '.8rem',
  },
}

class Folder extends React.Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (props.selectedFolders != state.selectedFolders) {
      return {
        selectedFolders: props.selectedFolders,
      };
    }
    return null;
  }

  onMouseOver(e, name) {
    let title = document.getElementById(name.toLowerCase().replace(' ', '-'));
      if (title && title.classList.contains('ellipsis')) {
        let width = title.getBoundingClientRect().width;
        title.classList.remove('ellipsis');
        title.classList.add('rev-ellipsis-folder');
        title.style.width = width;
      }
  }

  onMouseLeave(e, name) {
    let title = document.getElementById(name.toLowerCase().replace(' ', '-'));
      if (title && !title.classList.contains('ellipsis')) {
        title.classList.add('ellipsis');
        title.classList.remove('rev-ellipsis-folder');
      }
  }

  handleClick(e) {
    if (!this.props.selectionMode) {
      this.props.goToPath(this.props.folder.path);
    } else {
      let selected = this.props.selectedFolders.indexOf(this.props.folder.path);
      if (this.folder) {
        if (selected === -1) {
          this.folder.classList.add('selected');
        } else {
          this.folder.classList.remove('selected');
        }
      }
      this.props.selectFolder(this.props.folder.path);
    }
  }
  //
  render() {
    const { folder } = this.props;
    const { selectedFolders } = this.state;
    let selectionModeClass = (this.props.selectionMode)?' selection-mode ' : '';
    let selected = this.props.selectedFolders.indexOf(this.props.folder.path);

    if (!this.props.folder) return null;

    return (
      <div
        style={style.folder}
        ref={folder => this.folder = folder}
        className={"folder "+(selectionModeClass) + (selected >= 0 ? ' selected ': '')}
        onClick={(e) => this.handleClick(e)}
        onMouseOver={(e) => this.onMouseOver(e, folder.name)}
        onMouseLeave={(e) => this.onMouseLeave(e, folder.name)}
      >
        <div style={style.icon}>
          <i className="fa fa-folder-open"></i>
        </div>
        <div className={"folder-container"}>
          <div style={style.name} id={folder.name.toLowerCase().replace(' ', '-')} className={"folder-title ellipsis"}>
            {folder.name}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedFolders: state.explorer.selectedFolders,
  selectionMode: state.explorer.selectionMode
});

export default connect(mapStateToProps, { goToPath, selectFolder })(Folder);
