import React, { Component } from 'react';
import Home from '../components/Home/Home';
import { confs } from '../conf';
import { isMobile } from '../utils';
import { connect } from 'react-redux';
import { getAccess, goToPath } from '../components/Explorer/actions';
import Explorer from '../components/Explorer/Explorer';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import InformationHeader from '../components/Headers/InformationHeader';
import Toolbar from '../components/Headers/Toolbar';
import ToolZIP from '../components/Headers/ToolZIP';
import FullScreenCoverLoader from '../components/LoadingComponent/FullScreenCoverLoader';
// retrieve primary color
const { PRIMARY_BG } = confs;
// navs

const style = {
  explorerZone: {
    padding: '1rem 0',
    height: '100%',
    display: 'flex',
  },
  header: {
    background: 'white',
    boxShadow: '0 0 7px rgba(0,0,0,.4)',
    transition: 'all .15s ease',
    position: 'fixed',
    zIndex: '1000',
    top: '0',
    width: '100%',
  },
  main: {
    height: '100%',
  },
  flex: {
    flex: '1',
  }
}

class Main extends Component {
  componentDidMount() {
    const params = {
      access_token: this.props.auth.user.token,
    }
    this.props.getAccess(params);
  }

  getHeaderSize() {
    if (this.header) {
      let headerSize = this.header.getBoundingClientRect().height;
      // alert(headerSize);
      console.log(headerSize);
    }
  }

  render() {
    const { loading, currentFolder } = this.props;
    this.getHeaderSize();
    return (
        <div style={{...style.main, paddingTop: (isMobile() ? '94.6px':'150px')}}>
          <header ref={header => this.header = header} id={"app-header"} className={"app-header"} style={style.header}>
            <InformationHeader />
            <hr/>
            <Breadcrumb breadcrumb={currentFolder?currentFolder.breadcrumb:null} />
            {
              !isMobile() &&
                <>
                  <hr/>
                  <Toolbar />
                  <hr/>
                  <ToolZIP />
                </>
            }
          </header>
          <div style={style.explorerZone}>
            <FullScreenCoverLoader visible={this.props.zipping} />
            <Explorer />
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  explorer: state.explorer.explorer,
  currentFolder: state.explorer.currentFolder,
  loading: state.explorer.loading,
  zipping: state.explorer.zipping,
});

export default connect(mapStateToProps, { getAccess, goToPath })(Main);
