import React from 'react';
import Button from '../Button/Button';
import { connect } from 'react-redux';
import { selectFolder } from './actions';
import './style.css';

class File extends React.Component {


  handleClick(e) {
    if (this.props.selectionMode) {
      let selected = this.props.selectedFolders.indexOf(this.props.file.path);
      if (this.file) {
        if (selected === -1) {
          this.file.classList.add('selected');
        } else {
          this.file.classList.remove('selected');
        }
      }
      this.props.selectFolder(this.props.file.path);
    }
  }

  render() {
    const { file } = this.props;
    const typeFile = file.name.indexOf('FON') >= 0 ? 'type-fon': (file.name.indexOf('VCI') >= 0 ?'type-vci':'no-type');

    let selectionModeClass = (this.props.selectionMode)?' selection-mode ' : '';
    let selected = this.props.selectedFolders.indexOf(this.props.file.path);

    return (
      <div className={"d-flex"}>
        <div
          ref={(file) => this.file = file}
          style={{width: '100%'}}
          className={"ellipsis file "+(typeFile) + (selected > -1 ? ' selected ':'')+(selectionModeClass)}
          onClick={() => this.handleClick()}
        >
          {!this.props.selectionMode && <a download target={"_blank"} className={"btn-somesca mr-2"} href={file.url}>Télécharger</a>}
          <div className="d-flex ellipsis">
            <i className="fa fa-file-pdf-o"></i> <span className={"ellipsis"}>{file.name}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedFolders: state.explorer.selectedFolders,
  selectionMode: state.explorer.selectionMode,
});

export default connect(mapStateToProps, { selectFolder })(File);
